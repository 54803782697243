<template>
  <v-container grid-list-xl>
    <v-row>
      <v-col>
        <ayuda-video-player :video="selectedVideo"></ayuda-video-player>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <ayuda-menu :selectedCategory="selectedCategory"></ayuda-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AyudaMenu from "../components/features/ayuda/AyudaMenu.vue";
import AyudaVideoPlayer from "../components/features/ayuda/AyudaVideoPlayer.vue";
export default {
  components: { AyudaMenu, AyudaVideoPlayer },
  data() {
    return {
      selectedCategory: undefined,
      selectedVideo: undefined,
      categories: [
        {
          nombre: "Demografía",
          icon: "fa-chart-bar",
          selectedIndex: undefined,
          videos: [
            {
              titulo: "Creando una demografía",
              src: "videos/VIDEO_1.mp4",
              type: "video/mp4",
              duration: "00:24",
            },
            {
              titulo: "Asignando atributos",
              src: "videos/VIDEO_2.mp4",
              type: "video/mp4",
              duration: "00:40",
            },
          ],
        },
        {
          nombre: "Dimensión",
          icon: "fas fa-chart-line",
          selectedIndex: undefined,
          videos: [
            {
              titulo: "Creando una dimensión",
              src: "videos/VIDEO_3.mp4",
              type: "video/mp4",
              duration: "00:28",
            },
          ],
        },
        {
          nombre: "Encuesta",
          icon: "fas fa-chart-pie",
          selectedIndex: undefined,
          videos: [
            {
              titulo: "Creando una encuesta",
              src: "videos/VIDEO_4.mp4",
              type: "video/mp4",
              duration: "00:33",
            },
            {
              titulo: "Gestionando una encuesta",
              src: "videos/VIDEO_5.mp4",
              type: "video/mp4",
              duration: "00:27",
            },
            {
              titulo: "Asignando preguntas a una encuesta",
              src: "videos/VIDEO_6.mp4",
              type: "video/mp4",
              duration: "00:40",
            },
          ],
        },
        {
          nombre: "Proceso",
          icon: "fas fa-briefcase",
          selectedIndex: undefined,
          videos: [
            {
              titulo: "Creando un proceso",
              src: "videos/VIDEO_7.mp4",
              type: "video/mp4",
              duration: "00:27",
            },
            {
              titulo: "Registrando a los encuestados",
              src: "videos/VIDEO_8.mp4",
              type: "video/mp4",
              duration: "00:49",
            },
            {
              titulo: "Registrando a los encuestados (masivo)",
              src: "videos/VIDEO_9.mp4",
              type: "video/mp4",
              duration: "00:31",
            },
            {
              titulo: "Obteniendo los resultados",
              src: "videos/VIDEO_10.mp4",
              type: "video/mp4",
              duration: "00:26",
            },
          ],
        },
      ],
    };
  },
  provide() {
    return {
      getCategories: () => this.categories,
      selectCategory: (categoryId) => {
        console.log(categoryId);
        this.selectedCategory = categoryId;
      },
      selectVideo: (categoryId, videoId) => {
        if(categoryId === undefined || videoId === undefined) {
          return;
        }
        this.selectedVideo = this.categories[categoryId].videos[videoId];
        console.log(this.selectedVideo);
      }
    };
  },
  mounted() {
    this.selectedVideo = this.categories[0].videos[0];
  },
  props: [
    "popup",
  ]
};
</script>
