<template>
  <v-container grid-list-xl>
    <v-row justify="center">
      <crear-editar-proceso :show="showEditarProcesoModal">
        <v-card class="px-4 py-4 rounded" style="min-width: 400px;">
          <v-form @submit.prevent="editarProceso(procesoEditar)" v-if="editar" ref="formEditProceso">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-chip class="ml-0 mt-3" color="#E88E31" label text-color="white">
                    <v-list-item-action>
                      <v-icon>fas fa-briefcase</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <h3>EDITAR PROCESO</h3>
                    </v-list-item-content>
                  </v-chip>
                  <v-text-field :rules="inputRulesNameProceso" label="Nombre de proceso" v-model="procesoEditar.nombre">
                  </v-text-field>
                  <v-textarea :rules="inputRulesDescripcionProceso" label="Descripción de proceso"
                    v-model="procesoEditar.descripcion"></v-textarea>
                  <v-select v-model="procesoEditar.encuestaId" :items="encuestas" label="Encuesta" item-text="nombre"
                    item-value="_id" dense :rules="inputRulesSelectProceso" class="mt-3"></v-select>
                  <v-col class="d-flex justify-center">
                    <v-checkbox label="Utilizar fecha de inicio y fin" v-model="rangoFechaToggled"></v-checkbox>
                  </v-col>
                </v-col>
                <v-col v-if="rangoFechaToggled == true" class="d-flex justify-center">
                  <div>
                    <v-chip class="ml-0 mt-3 mb-2" color="#E88E31" label text-color="white">
                      <v-list-item-action>
                        <v-icon>fas fa-clock</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <h3>FECHA INICIO Y FIN</h3>
                      </v-list-item-content>
                    </v-chip>
                    <p style="max-width: 400px;">Aquí se puede seleccionar un rango de fechas para el proceso. La fecha de
                      inicio determina el día y hora en el cual el proceso se abrirá al encuestado. La fecha de fin
                      determina el día y la hora máxima para iniciar la encuesta.</p>
                    <fecha-inicio-fin v-model="fechaInicio" label="Fecha Inicio"></fecha-inicio-fin>
                    <fecha-inicio-fin v-model="fechaFin" label="Fecha Fin"></fecha-inicio-fin>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row align="center" justify="space-around" class="mt-3">
                <v-btn rounded color="warning" type="submit" class="ml-10">Modificar</v-btn>
                <v-btn rounded type="submit" class="mr-10"
                  @click="editar = false; showEditarProcesoModal = false; resetProceso()">Cancelar</v-btn>
              </v-row>
            </v-card-actions>
          </v-form>
          <v-form @submit.prevent="agregarProceso()" v-if="!editar" ref="formAddProceso">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-chip class="ml-0 mt-3" color="#E88E31" label text-color="white">
                    <v-list-item-action>
                      <v-icon>fas fa-briefcase</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <h3>NUEVO PROCESO</h3>
                    </v-list-item-content>
                  </v-chip>
                  <v-text-field :rules="inputRulesNameProceso" label="Nombre de proceso" v-model="proceso.nombre">
                  </v-text-field>
                  <v-textarea :rules="inputRulesDescripcionProceso" label="Descripción de proceso"
                    v-model="proceso.descripcion"></v-textarea>
                  <v-select v-model="proceso.encuestaId" :items="encuestas" name="category" label="Encuesta"
                    item-text="nombre" item-value="_id" dense :rules="inputRulesSelectProceso" class="mt-3">
                  </v-select>
                  <v-col class="d-flex justify-center">
                    <v-checkbox label="Utilizar fecha de inicio y fin" v-model="proceso.usa_rango_fecha"></v-checkbox>
                  </v-col>
                </v-col>
                <v-col v-if="proceso.usa_rango_fecha == true" class="d-flex justify-center">
                  <div>
                    <v-chip class="ml-0 mt-3 mb-2" color="#E88E31" label text-color="white">
                      <v-list-item-action>
                        <v-icon>fas fa-clock</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <h3>FECHA INICIO Y FIN</h3>
                      </v-list-item-content>
                    </v-chip>
                    <p style="max-width: 400px;">Aquí se puede seleccionar un rango de fechas para el proceso. La fecha de
                      inicio determina el día y hora en el cual el proceso se abrirá al encuestado. La fecha de fin
                      determina el día y la hora máxima para iniciar la encuesta.</p>
                    <fecha-inicio-fin v-model="fechaInicio" label="Fecha Inicio"></fecha-inicio-fin>
                    <fecha-inicio-fin v-model="fechaFin" label="Fecha Fin"></fecha-inicio-fin>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row align="center" justify="space-around" class="mt-3">
                <v-btn rounded color="success" type="submit" class="mt-3">Agregar</v-btn>
                <v-btn rounded @click="editar = false; showEditarProcesoModal = false">Cancelar</v-btn>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-card>
      </crear-editar-proceso>
      <v-col cols="12" md="12" sm="6">
        <v-card>
          <v-card-title>
            <v-chip color="#E88E31" label text-color="white">
              <v-icon left>widgets</v-icon>
              <h3>LISTA PROCESOS</h3>
            </v-chip>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar proceso" single-line hide-details>
            </v-text-field>
            <v-btn class="mt-3 ml-4 green" style="color:white" @click="showEditarProcesoModal = true">Agregar
              Proceso</v-btn>
          </v-card-title>

          <v-data-table fixed-header height="350px" :headers="dataProceso" :items="procesos" :search="search" sort-by="id"
            class="elevation-1" :items-per-page="5">
            <template v-slot:item.accion="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-2" fab dark x-small color="success" @click="activarEdicion(item._id)" v-bind="attrs"
                    v-on="on">
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar proceso</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-2" fab dark x-small color="error" @click="eliminarProceso(item._id)" v-bind="attrs"
                    v-on="on">
                    <v-icon dark>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar proceso</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-2" fab dark x-small color="orange"
                    @click="dialog = true; e1 = 1; modalProceso(item); listarProcesoEncuestados(item._id); procesoSeleccionado = item._id"
                    v-bind="attrs" v-on="on">
                    <v-icon dark>mdi-view-list</v-icon>
                  </v-btn>
                </template>
                <span>Gestionar proceso</span>
              </v-tooltip>
            </template>
            <template slot="no-data">
              No se encontró ningún registro
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable persistent>
      <v-card tile>
        <v-toolbar flat dark color="#298A45">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Configurar proceso - {{ procesoModal.nombre }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false; cleanArrayEncuestado()">
              Cerrar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-stepper non-linear v-model="e1">
            <v-stepper-header>
              <v-stepper-step editable step="1" color="#298A45" @click="listarProcesoEncuestados(procesoModal.id)">
                Preparación
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step editable step="2" color="#298A45"
                @click="listarProcesoEncuestadosResultado(procesoModal.id)">
                Resultados
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step editable step="3" color="#298A45" @click="verReporte(procesoModal.id)">
                Estadísticas y reportes
              </v-stepper-step>

            </v-stepper-header>

            <v-stepper-items>

              <v-stepper-content step="1">
                <v-row>
                  <v-col cols="12" md="10" sm="8">
                    <proceso-table :encuestados="procesoEncuestados$" :selectAll="selectAll"
                      :encuestadosSeleccionados="encuestadosSeleccionados$"
                      @editarEncuestado="activarEdicionProcesoEncuestado($event)"
                      @deleteEncuestado="deleteEncuestado($event)" :clearProcesoTable="clearProcesoTable$">
                    </proceso-table>
                    <v-row>
                      <v-col class="mt-2" cols="12" sm="12" md="6">
                        <v-btn outlined color="indigo" class="text-capitalize" bottom
                          @click="dialog2 = !dialog2; fechaInicio = ''; fechaFin = '';">
                          Agregar encuestado
                          <v-icon color="indigo" fab medium class="ml-3">mdi-plus-circle</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row justify="center">
                      <v-dialog v-model="dialog2" persistent max-width="600px">
                        <v-card>
                          <v-tabs v-model="tab" background-color="green darken-3" centered dark icons-and-text>
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#subscribe">
                              Encuestado individual
                              <v-icon>mdi-account</v-icon>
                            </v-tab>

                            <v-tab href="#contact">
                              Encuestado plantilla
                              <v-icon>mdi-account-multiple</v-icon>
                            </v-tab>
                          </v-tabs>

                          <v-tabs-items v-model="tab">
                            <v-tab-item :key="1" value="subscribe">
                              <v-card flat>
                                <v-form @submit.prevent="agregarEncuestado()" v-if="!editarProcesoEncuestado"
                                  ref="formAddEncuestado">
                                  <!-- <v-card-title>
                                        <span class="headline">Agregar encuestado individual</span>
                                      </v-card-title> -->
                                  <v-card-text>
                                    <v-container>
                                      <v-text-field :rules="inputRulesNameEncuestado" label="Nombres del encuestado"
                                        v-model="encuestado.nombre"></v-text-field>
                                      <v-text-field :rules="inputRulesApelEncuestado" label="Apellidos del encuestado"
                                        v-model="encuestado.apellido"></v-text-field>
                                      <v-text-field :rules="inputRulesEmailEncuestado" label="Correo del encuestado"
                                        v-model="encuestado.correo"></v-text-field>
                                      <v-radio-group v-model="encuestado.genero" row :rules="inputRulesGenderEncuestado">
                                        <v-radio label="Masculino" value="Masculino"></v-radio>
                                        <v-radio label="Femenino" value="Femenino"></v-radio>
                                      </v-radio-group>
                                    </v-container>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="success" rounded type="submit">
                                      Agregar
                                    </v-btn>
                                    <v-btn rounded @click="dialog2 = false" class="mr-7">
                                      Cancelar
                                    </v-btn>
                                  </v-card-actions>
                                </v-form>
                              </v-card>
                            </v-tab-item>
                            <v-tab-item :key="2" value="contact">
                              <v-card flat>
                                <v-form enctype="multipart/form-data" ref='fileForm'>
                                  <v-card-text>
                                    <v-container>
                                      <v-file-input multiple accept=".xlsx" label="Subir archivo EXCEL" outlined
                                        :rules="fileRules" prepend-icon="mdi-file-excel" v-model="chosenFile">
                                      </v-file-input>
                                      <div class="text-center mt-3" v-if="loading3">
                                        <v-progress-linear color="deep-purple accent-4" rounded height="6"
                                          :active="loading3" :indeterminate="loading3"></v-progress-linear>
                                      </div>
                                    </v-container>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="warning" rounded @click="downloadPlantilla()">
                                      Descargar plantilla
                                    </v-btn>
                                    <v-btn color="success" :loading="loading3" :disabled="loading3" rounded
                                      @click="uploadPlantilla();">
                                      Cargar plantilla
                                      <template v-slot:loader>
                                        <span>Subiendo...</span>
                                      </template>
                                    </v-btn>
                                    <v-btn rounded @click="dialog2 = false" class="mr-14">
                                      Cancelar
                                    </v-btn>
                                  </v-card-actions>
                                </v-form>
                              </v-card>
                            </v-tab-item>
                          </v-tabs-items>
                        </v-card>
                      </v-dialog>
                    </v-row>

                    <v-row justify="center">
                      <v-dialog v-model="dialog4" persistent max-width="600px">
                        <v-card>
                          <v-form @submit.prevent="editarEncuestado(procesoEncuetadoEditar)"
                            v-if="editarProcesoEncuestado" ref="formEditEncuestado">
                            <v-card-title>
                              <span class="headline">Editar Encuestado</span>
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <v-text-field :rules="inputRulesNameEncuestado" label="Nombres del encuestado"
                                  v-model="procesoEncuetadoEditar.nombre"></v-text-field>
                                <v-text-field :rules="inputRulesApelEncuestado" label="Apellidos del encuestado"
                                  v-model="procesoEncuetadoEditar.apellido"></v-text-field>
                                <v-text-field :rules="inputRulesEmailEncuestado" label="Correo del encuestado"
                                  v-model="procesoEncuetadoEditar.correo"></v-text-field>
                                <v-radio-group v-model="procesoEncuetadoEditar.genero" row
                                  :rules="inputRulesGenderEncuestado">
                                  <v-radio label="Masculino" value="Masculino"></v-radio>
                                  <v-radio label="Femenino" value="Femenino"></v-radio>
                                </v-radio-group>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="warning" rounded type="submit">
                                Modificar
                              </v-btn>
                              <v-btn rounded @click="dialog4 = false; editarProcesoEncuestado = false" class="mr-7">
                                Cancelar
                              </v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-row>

                    <!-- Enviar email -->
                    <v-row justify="center">
                      <v-dialog v-model="dialog3" persistent max-width="600px">
                        <v-card>
                          <form @submit.prevent="sendEmailProcesoEncuestado(procesoSendEmail)">
                            <v-card-title>
                              <span class="headline">Enviar correo</span>
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <v-text-field label="Asunto correo" v-model="procesoSendEmail.asunto"></v-text-field>
                                <v-textarea label="Primera parte del correo o introducción" rows="4"
                                  v-model="procesoSendEmail.entrada"></v-textarea>
                                <v-textarea label="Segunda parte del correo o despedida" rows="4"
                                  v-model="procesoSendEmail.despedida"></v-textarea>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="success" rounded @click="dialog3 = false;" type="submit">
                                Enviar
                              </v-btn>
                              <v-btn rounded @click="dialog3 = false" class="mr-7">
                                Cancelar
                              </v-btn>
                            </v-card-actions>
                          </form>
                        </v-card>
                      </v-dialog>
                    </v-row>

                    <!-- Reenviar email -->
                    <v-row justify="center">
                      <v-dialog v-model="dialog7" persistent max-width="600px">
                        <v-card>
                          <form @submit.prevent="remindEmailProcesoEncuestado(procesoRemindEmail)">
                            <v-card-title>
                              <span class="headline">Recordar correo</span>
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <v-text-field disabled label="Asunto correo" v-model="procesoRemindEmail.asunto">
                                </v-text-field>
                                <v-textarea disabled label="Primera parte del correo o introducción" rows="5"
                                  v-model="procesoRemindEmail.entrada"></v-textarea>
                                <v-textarea disabled label="Segunda parte del correo o despedida" rows="4"
                                  v-model="procesoRemindEmail.despedida"></v-textarea>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="success" rounded @click="dialog7 = false;" type="submit">
                                Recordar
                              </v-btn>
                              <v-btn rounded @click="dialog7 = false" class="mr-7">
                                Cancelar
                              </v-btn>
                            </v-card-actions>
                          </form>
                        </v-card>
                      </v-dialog>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="2" sm="4">
                    <v-card class="mx-auto" max-width="344" outlined>
                      <v-card-actions>
                        <v-btn outlined color="green" class="text-capitalize" bottom
                          @click="dialog3 = !dialog3; activarEdicionProcesoEnvio(procesoModal.mensajeId)">
                          Enviar email
                          <v-icon color="green" fab medium class="ml-8">mdi-email</v-icon>
                        </v-btn>
                      </v-card-actions>
                      <v-card-actions>
                        <v-btn outlined color="orange" class="text-capitalize" bottom
                          @click="dialog7 = !dialog7; activarEdicionProcesoRemindenvio(procesoModal.mensajeId)">
                          Recordar email
                          <v-icon color="orange" fab medium class="ml-3">mdi-email-open</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar encuestado" single-line
                      hide-details></v-text-field>
                  </v-card-title>
                  <v-data-table fixed-header height="235px" :headers="headers" :items="procesoEncuestadosResultado"
                    :search="search" sort-by="id" class="elevation-1" :items-per-page="100">
                    <template v-slot:item.accion="{ item }">
                      <v-btn class="mr-2" fab dark x-small color="success" @click="dialog6 = !dialog6; viewDetails(item)">
                        <v-icon dark>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <template slot="no-data">
                      No se encontró ningún registro
                    </template>
                  </v-data-table>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-row>
                  <v-col cols="12" md="9" sm="8">
                    <v-row class="mx-5">
                      <v-col cols="12" md="5" sm="4">
                        <v-card>
                          <v-col class="d-flex">
                            <v-select v-model="select1" :items="filtro1" label="Realizar el filtro por..." dense
                              v-on:change="changeFiltro1(`${select1}`)"></v-select>
                          </v-col>
                          <v-col>
                            <v-form v-if="this.select1 === 'Encuestados'">
                              <v-simple-table dense class="green lighten-5">
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">
                                        Resultados
                                      </th>
                                      <th class="text-left">
                                        Valor
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>Total encuestados</td>
                                      <td>{{ encuestados.totalEncuestados }}</td>
                                    </tr>
                                    <tr>
                                      <td>Encuestados terminados</td>
                                      <td>{{ encuestados.totalEncuestadosRecuperados }}</td>
                                    </tr>
                                    <tr>
                                      <td>Encuestados en proceso</td>
                                      <td>{{ encuestados.totalEncuestadosEnproceso }}</td>
                                    </tr>
                                    <tr>
                                      <td>Encuestados pendientes</td>
                                      <td>{{ encuestados.totalEncuestadosPendiente }}</td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-form>
                            <v-form v-if="this.select1 === 'Respuestas'">
                              <v-simple-table dense class="green lighten-5">
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">
                                        Resultados
                                      </th>
                                      <th class="text-left">
                                        Valor
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody v-if="respuestas.numopciones == 5">
                                    <tr>
                                      <td>Todas las respuestas</td>
                                      <td>{{ respuestas.allAnwers }}</td>
                                    </tr>
                                    <tr>
                                      <td>Solo respuestas positivas</td>
                                      <td>{{ respuestas.answersPositive }}</td>
                                    </tr>
                                    <tr>
                                      <td>Pomedio de positivos</td>
                                      <td>{{ respuestas.averagePositive }}</td>
                                    </tr>
                                    <tr>
                                      <td colspan="2"></td>
                                    </tr>
                                    <tr>
                                      <td>{{ respuestas.opcion1 }}</td>
                                      <td>{{ respuestas.valor1 }}</td>
                                    </tr>
                                    <tr>
                                      <td>{{ respuestas.opcion2 }}</td>
                                      <td>{{ respuestas.valor2 }}</td>
                                    </tr>
                                    <tr>
                                      <td>{{ respuestas.opcion3 }}</td>
                                      <td>{{ respuestas.valor3 }}</td>
                                    </tr>
                                    <tr>
                                      <td>{{ respuestas.opcion4 }}</td>
                                      <td>{{ respuestas.valor4 }}</td>
                                    </tr>
                                    <tr>
                                      <td>{{ respuestas.opcion5 }}</td>
                                      <td>{{ respuestas.valor5 }}</td>
                                    </tr>
                                  </tbody>
                                  <tbody v-if="respuestas.numopciones == 6">
                                    <tr>
                                      <td>Todas las respuestas</td>
                                      <td>{{ respuestas.allAnwers }}</td>
                                    </tr>
                                    <tr>
                                      <td>Solo respuestas positivas</td>
                                      <td>{{ respuestas.answersPositive }}</td>
                                    </tr>
                                    <tr>
                                      <td>Pomedio de positivos</td>
                                      <td>{{ respuestas.averagePositive }}</td>
                                    </tr>
                                    <tr>
                                      <td colspan="2"></td>
                                    </tr>
                                    <tr>
                                      <td>{{ respuestas.opcion1 }}</td>
                                      <td>{{ respuestas.valor1 }}</td>
                                    </tr>
                                    <tr>
                                      <td>{{ respuestas.opcion2 }}</td>
                                      <td>{{ respuestas.valor2 }}</td>
                                    </tr>
                                    <tr>
                                      <td>{{ respuestas.opcion3 }}</td>
                                      <td>{{ respuestas.valor3 }}</td>
                                    </tr>
                                    <tr>
                                      <td>{{ respuestas.opcion4 }}</td>
                                      <td>{{ respuestas.valor4 }}</td>
                                    </tr>
                                    <tr>
                                      <td>{{ respuestas.opcion5 }}</td>
                                      <td>{{ respuestas.valor5 }}</td>
                                    </tr>
                                    <tr>
                                      <td>{{ respuestas.opcion6 }}</td>
                                      <td>{{ respuestas.valor6 }}</td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-form>
                          </v-col>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="7" sm="8">
                        <v-card>
                          <v-col class="d-flex">
                            <v-select v-model="select2" :items="filtro2" label="Realizar el filtro por..." dense
                              v-on:change="changeFiltro2(`${select2}`)"></v-select>
                          </v-col>
                          <v-col>
                            <v-form v-if="this.select2 === 'Resultado: Todas las respuestas'">
                              <v-simple-table dense class="green lighten-5">
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">
                                        Descripción
                                      </th>
                                      <th class="text-left">
                                        % Resultado
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="item in allanswers" :key="item.nameDimension">
                                      <td>{{ item.nameDimension }}</td>
                                      <td>{{ item.porcentaje }}</td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-form>
                            <v-form v-if="this.select2 === 'Resultado: Solo respuestas positivas'">
                              <v-simple-table dense class="green lighten-5">
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">
                                        Descripción
                                      </th>
                                      <th class="text-left">
                                        % Resultado
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="item in allanswers" :key="item.nameDimension">
                                      <td>{{ item.nameDimension }}</td>
                                      <td>{{ item.porcentaje }}</td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-form>
                            <v-form v-if="this.select2 === 'Resultado: Promedio de positivos'">
                              <v-simple-table dense class="green lighten-5">
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">
                                        Descripción
                                      </th>
                                      <th class="text-left">
                                        % Resultado
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="item in allanswers" :key="item.nameDimension">
                                      <td>{{ item.nameDimension }}</td>
                                      <td>{{ item.porcentaje }}</td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-form>
                          </v-col>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="3" sm="4">
                    <v-form>
                      <v-card class="mx-auto" max-width="344" outlined>
                        <v-card-actions class="pa-0">
                          <reporte-pdf :procesoId="procesoModal.id"
                            @activarEdicionIntroductionReport="activarEdicionIntroductionReport($event)" />
                        </v-card-actions>
                        <v-card-actions class="pa-0" outline>
                          <extraer-reporte :procesoId="procesoModal.id" />
                        </v-card-actions>
                        <v-card-actions> <v-btn outlined color="warning" class="text-capitalize" :loading="loading2"
                            :disabled="loading2" bottom @click="reportGeneralExcel(); loading2 = true" block> Reporte
                            EXCEL <template v-slot:loader>
                              <span>Generando...</span>
                            </template>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                    <div class="text-center mt-3" v-if="loading">
                      <v-progress-linear color="#298A45" rounded height="6" :active="loading" :indeterminate="loading">
                      </v-progress-linear>
                    </div>
                    <div class="text-center mt-3" v-if="loading2">
                      <v-progress-linear color="#298A45" rounded height="6" :active="loading2"
                        :indeterminate="loading2"></v-progress-linear>
                    </div>
                  </v-col>
                </v-row>
              </v-stepper-content>

            </v-stepper-items>

          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog max-width="1000" persistent v-model="dialog5">
      <v-card>
        <v-toolbar color="purple lighten-2" dark>DATOS ESTADÍSTICOS</v-toolbar>
        <v-row class="mx-5">
          <v-col cols="12" md="4" sm="4">
            <v-card>
              <v-col class="d-flex">
                <v-select v-model="select1" :items="filtro1" label="Realizar el filtro por..." dense
                  v-on:change="changeFiltro1(`${select1}`)"></v-select>
              </v-col>
              <v-col>
                <v-form v-if="this.select1 === 'Encuestados'">
                  <v-simple-table dense class="purple lighten-5">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Resultados
                          </th>
                          <th class="text-left">
                            Valor
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Total encuestados</td>
                          <td>{{ encuestados.totalEncuestados }}</td>
                        </tr>
                        <tr>
                          <td>Encuestados terminados</td>
                          <td>{{ encuestados.totalEncuestadosRecuperados }}</td>
                        </tr>
                        <tr>
                          <td>Encuestados en proceso</td>
                          <td>{{ encuestados.totalEncuestadosEnproceso }}</td>
                        </tr>
                        <tr>
                          <td>Encuestados pendientes</td>
                          <td>{{ encuestados.totalEncuestadosPendiente }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-form>
                <v-form v-if="this.select1 === 'Respuestas'">
                  <v-simple-table dense class="purple lighten-5">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Resultados
                          </th>
                          <th class="text-left">
                            Valor
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="respuestas.numopciones == 5">
                        <tr>
                          <td>Todas las respuestas</td>
                          <td>{{ respuestas.allAnwers }}</td>
                        </tr>
                        <tr>
                          <td>Solo respuestas positivas</td>
                          <td>{{ respuestas.answersPositive }}</td>
                        </tr>
                        <tr>
                          <td>Pomedio de positivos</td>
                          <td>{{ respuestas.averagePositive }}</td>
                        </tr>
                        <tr>
                          <td colspan="2"></td>
                        </tr>
                        <tr>
                          <td>{{ respuestas.opcion1 }}</td>
                          <td>{{ respuestas.valor1 }}</td>
                        </tr>
                        <tr>
                          <td>{{ respuestas.opcion2 }}</td>
                          <td>{{ respuestas.valor2 }}</td>
                        </tr>
                        <tr>
                          <td>{{ respuestas.opcion3 }}</td>
                          <td>{{ respuestas.valor3 }}</td>
                        </tr>
                        <tr>
                          <td>{{ respuestas.opcion4 }}</td>
                          <td>{{ respuestas.valor4 }}</td>
                        </tr>
                        <tr>
                          <td>{{ respuestas.opcion5 }}</td>
                          <td>{{ respuestas.valor5 }}</td>
                        </tr>
                      </tbody>
                      <tbody v-if="respuestas.numopciones == 6">
                        <tr>
                          <td>Todas las respuestas</td>
                          <td>{{ respuestas.allAnwers }}</td>
                        </tr>
                        <tr>
                          <td>Solo respuestas positivas</td>
                          <td>{{ respuestas.answersPositive }}</td>
                        </tr>
                        <tr>
                          <td>Pomedio de positivos</td>
                          <td>{{ respuestas.averagePositive }}</td>
                        </tr>
                        <tr>
                          <td colspan="2"></td>
                        </tr>
                        <tr>
                          <td>{{ respuestas.opcion1 }}</td>
                          <td>{{ respuestas.valor1 }}</td>
                        </tr>
                        <tr>
                          <td>{{ respuestas.opcion2 }}</td>
                          <td>{{ respuestas.valor2 }}</td>
                        </tr>
                        <tr>
                          <td>{{ respuestas.opcion3 }}</td>
                          <td>{{ respuestas.valor3 }}</td>
                        </tr>
                        <tr>
                          <td>{{ respuestas.opcion4 }}</td>
                          <td>{{ respuestas.valor4 }}</td>
                        </tr>
                        <tr>
                          <td>{{ respuestas.opcion5 }}</td>
                          <td>{{ respuestas.valor5 }}</td>
                        </tr>
                        <tr>
                          <td>{{ respuestas.opcion6 }}</td>
                          <td>{{ respuestas.valor6 }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-form>
              </v-col>
            </v-card>
          </v-col>
          <v-col cols="12" md="8" sm="8">
            <v-card>
              <v-col class="d-flex">
                <v-select v-model="select2" :items="filtro2" label="Realizar el filtro por..." dense
                  v-on:change="changeFiltro2(`${select2}`)"></v-select>
              </v-col>
              <v-col>
                <v-form v-if="this.select2 === 'Resultado: Todas las respuestas'">
                  <v-simple-table dense class="purple lighten-5">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Descripción
                          </th>
                          <th class="text-left">
                            % Resultado
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in allanswers" :key="item.nameDimension">
                          <td>{{ item.nameDimension }}</td>
                          <td>{{ item.porcentaje }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-form>
                <v-form v-if="this.select2 === 'Resultado: Solo respuestas positivas'">
                  <v-simple-table dense class="purple lighten-5">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Descripción
                          </th>
                          <th class="text-left">
                            % Resultado
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in allanswers" :key="item.nameDimension">
                          <td>{{ item.nameDimension }}</td>
                          <td>{{ item.porcentaje }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-form>
                <v-form v-if="this.select2 === 'Resultado: Promedio de positivos'">
                  <v-simple-table dense class="purple lighten-5">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Descripción
                          </th>
                          <th class="text-left">
                            % Resultado
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in allanswers" :key="item.nameDimension">
                          <td>{{ item.nameDimension }}</td>
                          <td>{{ item.porcentaje }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-form>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mx-5">
          <v-col cols="12" md="12" sm="6">
            <v-form>
              <v-card>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <v-checkbox v-model="selectedCheckReport" label="Detalle por Dimensiones" color="orange" value="first"
                      hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-checkbox v-model="selectedCheckReport" label="Tabla de porcentajes por pregunta" color="orange"
                      value="second" hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-checkbox v-model="selectedCheckReport" label="Gráfico de porcentaje por pregunta" color="orange"
                      value="third" hide-details></v-checkbox>
                  </v-col>
                </v-row>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn rounded @click="dialog5 = false" class="mr-5">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog6" persistent max-width="600px">
      <v-card>
        <form>
          <v-card-title>
            <span class="headline">Detalle Encuestado</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-text-field label="Nombre del encuestado" prepend-icon="mdi-account" disabled
                v-model="fullnameEncuestado"></v-text-field>
              <v-data-table :headers="detailsHeaderEncuestado" :items="detailsBodyEncuestado" class="elevation-1"
                hide-default-footer>
                <template v-slot:item.estado="{ item }">
                  <v-chip :color="getColor(item.estado)" dark>
                    {{ item.estado }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn rounded @click="dialog6 = false" class="mr-5">
              Cerrar
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog8" persistent max-width="600px">
      <v-card>
        <v-form @submit.prevent="editarIntroductionReport(introductionReportEditar)" ref="formEditIntroductionReport">
          <v-card-text>
            <v-container>
              <v-textarea v-model="introductionReportEditar.descripcion" :rules="inputRulesDescripcionIntroductionReport"
                auto-grow filled color="deep-purple" label="Introducción del reporte" rows="1"></v-textarea>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" rounded type="submit">
              Guardar
            </v-btn>
            <v-btn rounded @click="dialog8 = false" class="mr-7">
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>



    <v-snackbar v-model="snackbar">
      {{ mensaje.texto }}
      <template>
        <v-btn color="blue" text @click="snackbar = false" class="ml-16">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>

import { mapState } from 'vuex';
import ProcesoTable from '../components/features/proceso/ProcesoTable.vue';
import ReportePDF from '../components/features/proceso/ReportePDF.vue';
import ExtraerReporte from '../components/features/proceso/ExtraerReporte.vue';
import CrearEditarProceso from '../components/features/proceso/CrearEditarProceso.vue';
import FechaInicioFin from "../components/features/proceso/FechaInicioFin.vue";
import { BehaviorSubject, Subject } from 'rxjs';

export default {
  components: {
    ProcesoTable,
    CrearEditarProceso,
    'reporte-pdf': ReportePDF,
    'extraer-reporte': ExtraerReporte,
    "fecha-inicio-fin": FechaInicioFin
  },
  data() {
    return {
      e1: 1,
      rangoFechaToggled: false,
      fechaInicio: '',
      fechaFin: '',
      showEditarProcesoModal: false,
      allSelected: false,
      procesos: [],
      mensaje: { color: '', texto: '' },
      proceso: {
        nombre: '',
        descripcion: '',
        encuestaId: ''
      },
      editar: false,
      procesoEditar: {
        encuestaId: '',
      },
      search: '',
      snackbar: false,
      dataProceso: [
        { text: 'PROCESO', value: 'nombre', width: '25%' },
        { text: 'DESCRIPCIÓN', value: 'descripcion', width: '30%' },
        { text: 'ENCUESTA', value: 'encuesta', width: '20%' },
        { text: 'ACCIONES', value: 'accion', sortable: false },
      ],
      encuestas: [],
      dialog: false,
      inputRulesNameProceso: [
        v => !!v || 'Nombre de proceso es requerido'
      ],
      inputRulesDescripcionProceso: [
        v => !!v || 'Descripción de proceso es requerido'
      ],
      inputRulesSelectProceso: [
        v => !!v || 'Seleccione una encuesta'
      ],
      //Modal 2
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog5: false,
      dialog6: false,
      dialog7: false,
      dialog8: false,
      procesoModal: {
        id: '',
        nombre: '',
        mensajeId: ''
      },
      //Preparación Persona
      encuestado: {
        nombre: '',
        apellido: '',
        correo: '',
        genero: ''
      },
      // rulesemail: [
      //   value => !!value || 'Requerido',
      //   value => (value || '').length <= 40 || 'Max 40 caracteres',
      //   value => {
      //     const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      //     return pattern.test(value) || 'Correo invalido'
      //   },
      // ],
      editarProcesoEncuestado: false,
      dataProcesoEncuestado: {
        procesoId: '',
        encuestadoId: '',
        estadoEmail: 'Ingresado',
        estadoProceso: '0'
      },
      procesoEncuestados: [],
      procesoEncuestados$: new BehaviorSubject([]),
      encuestadosSeleccionados: [],
      encuestadosSeleccionados$: new BehaviorSubject([]),
      clearProcesoTable$: new Subject(),
      procesoEncuetadoEditar: {},
      sendEmail: [],
      procesoSendEmail: {
        asunto: 'Encuesta de clima laboral',
        entrada: 'Estimado encuestado,\nEs importante para la empresa saber tu opinión sobre diferentes aspectos del entorno laboral, por lo que agradecemos completar la encuesta con la mayor sinceridad. Toda la información recopilada es confidencial.',
        despedida: 'Agradecemos tu cooperación.\nRecursos Humanos',
      },
      procesoRemindEmail: {},
      inputRulesNameEncuestado: [
        v => !!v || 'Nombre es requerido'
      ],
      inputRulesApelEncuestado: [
        v => !!v || 'Apellido es requerido'
      ],
      inputRulesEmailEncuestado: [
        value => !!value || 'Email es requerido',
        value => (value || '').length <= 80 || 'Max 80 caracteres',
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Email invalido'
        }
      ],
      inputRulesGenderEncuestado: [
        v => !!v || 'Género es requerido'
      ],
      inputRulesCheckboxes: [
        v => !!v || 'You must agree to continue!'
      ],
      tab: null,
      fileRules: [
        v => !!v || 'Archivo excel es requerido',
        v => (v && v.length > 0) || 'Archivo excel es requerido',
      ],
      chosenFile: null,
      procesoEncuestadosResultado: [],
      headers: [
        // { text: 'ID', align: 'left', sortable: false,value: '_id'},
        { text: 'NOMBRE', value: 'nombre' },
        { text: 'APELLIDO', value: 'apellido' },
        { text: 'GÉNERO', value: 'genero' },
        { text: 'CORREO', value: 'correo' },
        { text: 'ESTADO PROCESO', value: 'estadoProceso' },
        { text: 'ACCIONES', value: 'accion', sortable: false },
      ],
      select1: 'Encuestados',
      filtro1: ['Encuestados', 'Respuestas'],
      filtro2: ['Resultado: Todas las respuestas', 'Resultado: Solo respuestas positivas', 'Resultado: Promedio de positivos'],
      encuestados: [],
      respuestas: [],
      select2: 'Resultado: Todas las respuestas',
      allanswers: [],
      selectedCheckReport: [],
      report: {
        checks: [],
        filter: ''
      },
      reportExcel: {
        filter: ''
      },
      filter: '',
      estadoEncuestaEncuestado: '',
      fullnameEncuestado: '',
      detailsHeaderEncuestado: [
        {
          text: 'NOMBRE DE ENCUESTA',
          align: 'start',
          sortable: false,
          value: 'nombre',
        },
        { text: 'ESTADO DE ECUESTA', sortable: false, value: 'estado' }
      ],
      detailsBodyEncuestado: [],
      introductionReportEditar: {},
      inputRulesDescripcionIntroductionReport: [
        v => !!v || 'Nombre de dimensión es requerido',
        v => (v && v.length <= 400) || 'Max 400 caracteres.'
      ],
      loader: null,
      loader2: null,
      loader3: null,
      loading: false,
      loading2: false,
      loading3: false,
    }
  },
  computed: {
    ...mapState(['token']),
    selectAll: {
      get: function () {
        return this.encuestadosSeleccionados === this.procesoEncuestados;
      },
    }
  },
  created() {
    this.listarProcesos();
    this.encuestadosSeleccionados$.subscribe((data) => {
      this.encuestadosSeleccionados = data;
      console.log(`Procesos seleccionados cambiaron a ${data.length} elementos.`)
    })
  },
  methods: {
    agregarProceso() {
      if (this.proceso.usa_rango_fecha == true && this.fechaInicio !== "" && this.fechaFin !== "") {
        this.proceso.rango_fecha = [this.fechaInicio, this.fechaFin];
      } else {
        this.proceso.rango_fecha = undefined;
      }
      if (this.$refs.formAddProceso.validate()) {
        this.axios.post(`${process.env.VUE_APP_BACKEND_NEW_URL}/procesos/nuevo-proceso`, this.proceso)
          .then(res => {
            this.procesos.push(res.data)
            this.editar = false;
            this.showEditarProcesoModal = false;
            this.proceso.nombre = ''
            this.proceso.descripcion = ''
            this.proceso.encuestaId = ''
            this.snackbar = true
            this.mensaje.texto = '¡Proceso Agregado!'
            this.listarProcesos();
            this.$refs.formAddProceso.reset()
          })
          .catch(e => {
            console.log(e.response);
            if (e.response.data.error.errors.nombre.message) {
              this.snackbar = true
              this.mensaje.texto = e.response.data.error.errors.nombre.message
            } else {
              this.snackbar = true
              this.mensaje.texto = 'Error de sistema'
            }
          })
      }
    },
    activarEdicion(id) {
      this.fechaInicio = "";
      this.fechaFin = "";
      this.showEditarProcesoModal = true;
      this.editar = true;
      this.axios.get(`${process.env.VUE_APP_BACKEND_NEW_URL}/procesos/${id}`)
        .then(res => {
          this.procesoEditar = res.data;
          console.log("Proceso Editar:", this.procesoEditar);
          try {
            this.fechaInicio = new Date(this.procesoEditar.fechaInicio).toISOString();
          } catch (e) {
            this.fechaInicio = "";
          }
          try {
            this.fechaFin = new Date(this.procesoEditar.fechaFin).toISOString();
          } catch (e) {
            this.fechaFin = "";
          }
          if (this.procesoEditar.fechaInicio) {
            this.fechaInicio = new Date(this.procesoEditar.fechaInicio).toISOString();
          }
          if (this.procesoEditar.fechaFin) {
            this.fechaFin = new Date(this.procesoEditar.fechaFin).toISOString();
          }
          if (this.fechaInicio !== this.fechaFin && this.fechaInicio !== undefined && this.fechaFin !== undefined) {
            const rango_fecha = [this.fechaInicio, this.fechaFin];
            this.rangoFechaEdicion = rango_fecha;
            this.rangoFechaToggled = true;
          }
          console.log("Proceso Editar:", this.procesoEditar);
        })
        .catch(e => {
          console.error(e);
        })
    },
    eliminarProceso(id) {
      this.$confirm('¿Deseas eliminar el proceso?', { title: 'Alerta' })
        .then(res => {
          if (res == true) {
            this.axios.delete(`/proceso/${id}`)
              .then(res => {
                const index = this.procesos.findIndex(item => item._id === res.data._id);
                this.procesos.splice(index, 1);
                this.snackbar = true
                this.mensaje.texto = '¡Proceso eliminado!';
                this.listarProcesos();
              })
              .catch(e => {
                console.log(e.response);
              })
          }
        })
    },
    editarProceso(item) {
      if (this.$refs.formEditProceso.validate()) {
        if (this.rangoFechaToggled && this.fechaInicio !== "" && this.fechaFin !== "") {
          item.rango_fecha = [this.fechaInicio, this.fechaFin];
        } else {
          item.rango_fecha = [];
        }
        this.fechaInicio = "";
        this.fechaFin = "";
        console.log("Item:", item);
        this.axios.put(`${process.env.VUE_APP_BACKEND_NEW_URL}/procesos/${item._id}`, item)
          .then(res => {
            const index = this.procesos.findIndex(n => n._id === res.data._id);
            this.procesos[index].nombre = res.data.nombre;
            this.procesos[index].descripcion = res.data.descripcion;
            this.procesos[index].encuesta = res.data.encuesta;
            this.procesos[index].encuestaId = res.data.encuestaId;
            this.procesos[index].rango_fecha = [this.fechaInicio, this.fechaFin];
            this.snackbar = true
            this.mensaje.texto = '¡Proceso editado!';
            this.editar = false;
            this.showEditarProcesoModal = false;
            this.$refs.formEditProceso.reset();
          })
          .catch(e => {
            console.error(e);
          })
      } else {
        console.error('Error en el formulario');
      }
    },
    listarProcesos() {
      //listar procesos
      this.axios.get('/procesos')
        .then(response => {
          this.procesos = response.data;
          this.procesos.sort((a, b) => new Date(b.date) - new Date(a.date));
        });
      //listar encuestas para el SELECT
      this.axios.get(`/encuestasByStatus`)
        .then(res => {
          if (res.data === null || res.data.length == 0) {
            this.encuestas = [];
          } else {
            this.encuestas = res.data
          }
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    modalProceso(item) {
      this.clearProcesoTable$.next(true);
      this.procesoModal.id = item._id;
      this.procesoModal.nombre = item.nombre;
      this.procesoModal.mensajeId = item.mensajeId;
    },
    //STEP 1
    agregarEncuestado() {
      if (this.$refs.formAddEncuestado.validate()) {
        this.axios.post('/nuevo-encuestado', this.encuestado)
          .then(res => {
            const nuevoEncuestado = res.data
            this.encuestado.nombre = ''
            this.encuestado.apellido = ''
            this.encuestado.correo = ''
            this.encuestado.genero = ''
            this.dataProcesoEncuestado.procesoId = this.procesoModal.id
            this.dataProcesoEncuestado.encuestadoId = res.data._id
            this.axios.post('/nuevo-procesoencuestado', this.dataProcesoEncuestado)
              .then(res => {
                nuevoEncuestado.estadoEmail = res.data.estadoEmail
                this.procesoEncuestados.push(nuevoEncuestado);
                this.procesoEncuestados$.next(this.procesoEncuestados);
                this.snackbar = true
                this.mensaje.texto = '¡Encuestado Agregado!'
                this.dialog2 = false
              })
              .catch(e => {
                this.snackbar = true
                this.mensaje.texto = 'Error de sistema'
              })
          })
          .catch(e => {
            console.log(e.response);
            if (e.response.data.error.errors.nombre.message) {
              this.mensaje.texto = e.response.data.error.errors.nombre.message
            } else {
              this.mensaje.texto = 'Error de sistema'
            }
            this.mensaje.texto = 'Error'
          })
      }
    },
    deleteEncuestado(id) {
      this.$confirm('¿Deseas eliminar al encuestado?', { title: 'Alerta' })
        .then(res => {
          if (res == true) {
            this.axios.delete(`/encuestado/${id}`)
              .then(res => {
                const index = this.procesoEncuestados.findIndex(item => item._id === res.data._id);
                this.procesoEncuestados.splice(index, 1);
                this.procesoEncuestados$.next(this.procesoEncuestados);
                this.axios.delete(`/procesoencuestado/${id}`)
                  .then(res => {
                    this.snackbar = true
                    this.mensaje.texto = '¡Encuestado eliminado!'
                    this.encuestadosSeleccionados = [];
                    this.encuestadosSeleccionados$.next(this.encuestadosSeleccionados);
                    this.listarProcesoEncuestados(this.procesoModal.id);
                    this.listarProcesoEncuestadosResultado(this.procesoModal.id);
                  })
              })
              .catch(e => {
                console.log(e.response);
              })
          }
        })
    },
    listarProcesoEncuestados(id) {
      this.procesoEncuestados = [];
      this.axios.get(`/procesoencuestados/${id}`)
        .then(res => {
          if (res.data === null || res.data.length == 0) {
            this.procesoEncuestados = [];
          } else {
            this.procesoEncuestados = res.data;
          }
          this.procesoEncuestados$.next(this.procesoEncuestados);
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    activarEdicionProcesoEncuestado(id) {
      this.editarProcesoEncuestado = true;
      this.axios.get(`/encuestado/${id}`)
        .then(res => {
          this.dialog4 = true
          this.procesoEncuetadoEditar = res.data;
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    editarEncuestado(item) {
      if (this.$refs.formEditEncuestado.validate()) {
        this.axios.put(`/encuestado/${item._id}`, item)
          .then(res => {
            const index = this.procesoEncuestados.findIndex(n => n._id === res.data._id);
            this.procesoEncuestados[index].nombre = res.data.nombre;
            this.procesoEncuestados[index].apellido = res.data.apellido;
            this.procesoEncuestados[index].genero = res.data.genero;
            this.procesoEncuestados[index].correo = res.data.correo;
            this.procesoEncuestados$.next(this.procesoEncuestados);
            this.snackbar = true
            this.mensaje.texto = '¡Encuestado editado!'
            this.editarProcesoEncuestado = false;
            this.dialog4 = false
          })
          .catch(e => {
            console.log(e.response);
          })
      }
    },
    downloadPlantilla() {
      const procesoId = this.procesoModal.id;
      this.axios.get(`/downloadPlantilla/${procesoId}`, { responseType: "blob" })
        .then(res => {
          var fileURL2 = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink2 = document.createElement('a');
          fileLink2.href = fileURL2;
          fileLink2.setAttribute('download', this.procesoModal.nombre + '.xlsx');
          document.body.appendChild(fileLink2);
          fileLink2.click();
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    uploadPlantilla() {
      if (this.$refs.fileForm.validate()) {
        this.loading3 = true;
        let formData = new FormData();
        formData.append('file', this.chosenFile[0]);
        formData.append('procesoId', this.procesoModal.id);
        this.loader3 = false;
        this.axios.post('/uploadPlantilla', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(res => {
            // this.procesoEncuestados.push(res.data);
            for (let i = 0; i < res.data.length; i++) {
              const encuestadoProcess = res.data[i]
              encuestadoProcess.estadoEmail = 'Ingresado'
              this.procesoEncuestados.push(encuestadoProcess);
              this.procesoEncuestados$.next(this.procesoEncuestados);
            }
            this.snackbar = true
            this.mensaje.texto = '¡Encuestados Agregados!'
            this.dialog2 = false
            this.chosenFile = null
            this.loading3 = false;
            this.loader3 = null;
          })
          .catch(e => {
            this.loading3 = false;
            this.loader3 = null;
            console.log(e.response);
          })
      }
    },
    activarEdicionProcesoEnvio(id) {
      if (this.encuestadosSeleccionados.length == 0) {
        this.dialog3 = false
        this.snackbar = true
        this.mensaje.texto = '¡Selecciona encuestados!'
      } else {
        this.axios.get(`/mensaje/${id}`)
          .then(res => {
            console.log(this.procesoSendEmail);
            this.procesoSendEmail = res.data;
            console.log(this.procesoSendEmail);
          })
          .catch(e => {
            console.log(e.response);
          })
      }

    },
    activarEdicionProcesoRemindenvio(id) {
      if (this.encuestadosSeleccionados.length == 0) {
        this.dialog7 = false
        this.snackbar = true
        this.mensaje.texto = '¡Selecciona encuestados!'
      } else {
        this.axios.get(`/mensaje/${id}`)
          .then(res => {
            res.data.asunto = 'Recordatorio: ' + res.data.asunto;
            res.data.entrada = `Nos permitimos recordarle que tiene una encuesta pendiente. Su opinión es muy valiosa para nosotros, por lo que le agradecemos que tome un momento de su tiempo para que la responda con la mayor sinceridad.`;
            res.data.despedida = `Apreciamos su colaboración de participar en la presente encuesta.`;
            this.procesoRemindEmail = res.data;
          })
          .catch(e => {
            console.log(e.response);
          })
      }

    },
    afterEmail() {
      console.log(`Sent to ${this.encuestadosSeleccionados}`);
      this.encuestadosSeleccionados = [];
      this.encuestadosSeleccionados$.next(this.encuestadosSeleccionados);
      this.listarProcesoEncuestados(this.procesoModal.id);
    },
    sendEmailProcesoEncuestado(item) {
      console.log("Item to send", JSON.stringify(item));
      this.axios.put(`/mensaje/${item._id}`, item)
        .then(res => {
          this.sendEmail = item;
          this.sendEmail.listEncuestados = { encuestadosId: this.encuestadosSeleccionados };
          this.sendEmail.procesoId = this.procesoModal.id;
          this.axios.post('/send-email', this.sendEmail)
            .then(res => {
              this.afterEmail();
/*               for (let i = 0; i < res.data.length; i++) {
                const index = this.procesoEncuestados.findIndex(n => n._id === res.data[i]);
                this.procesoEncuestados[index].estadoEmail = 'Enviado';
              }
              this.procesoEncuestados$.next(this.procesoEncuestados);
              this.encuestadosSeleccionados = [];
              this.encuestadosSeleccionados$.next(this.encuestadosSeleccionados);
 */              this.snackbar = true
              this.mensaje.texto = '¡Mensaje enviado!'
              this.listarProcesoEncuestadosResultado(this.procesoModal.id);
            })
            .catch(e => {
              console.log(e.response);
            })
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    remindEmailProcesoEncuestado(item) {
      this.sendEmail = item
      this.sendEmail.listEncuestados = { encuestadosId: this.encuestadosSeleccionados };
      this.sendEmail.procesoId = this.procesoModal.id;
      this.axios.post('/remind-email', this.sendEmail)
        .then(res => {
          console.log(res.data);
          for (let i = 0; i < res.data.length; i++) {
              const index = this.procesoEncuestados.findIndex(n => n._id === res.data[i]._id && n.estadoEmail === 'Enviado');
              if (index !== -1) {
                  this.procesoEncuestados[index].estadoEmail = 'Enviado';
              }
          }
          this.procesoEncuestados$.next(this.procesoEncuestados);
          this.encuestadosSeleccionados = [];
          this.encuestadosSeleccionados$.next(this.encuestadosSeleccionados);
          this.snackbar = true
          this.mensaje.texto = '¡Mensaje Reenviado!'
          this.afterEmail();
          this.listarProcesoEncuestadosResultado(this.procesoModal.id);
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    resetProceso() {
      this.$refs.formEditProceso.reset()
    },
    //STEP 2
    listarProcesoEncuestadosResultado(id) {
      this.procesoEncuestadosResultado = [];
      this.axios.get(`/procesoencuestadosresultado/${id}`)
        .then(res => {
          if (res.data === null || res.data.length == 0) {
            this.procesoEncuestadosResultado = [];
          } else {
            this.procesoEncuestadosResultado = res.data;
          }
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    verReporte() {
      this.select1 = 'Encuestados'
      this.select2 = 'Resultado: Todas las respuestas'
      this.selectedCheckReport = []
      const procesoId = this.procesoModal.id
      this.axios.get(`/resultadoencuestados/${procesoId}`)
        .then(res => {
          this.encuestados = res.data;
        })
        .catch(e => {
          console.log(e.response);
        })
      this.axios.get(`/allanswers/${procesoId}`)
        .then(res => {
          this.allanswers = res.data;
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    changeFiltro1(a) {
      if (a == 'Encuestados') {
        console.log('Encuestados');
      } else {
        const procesoId = this.procesoModal.id
        this.axios.get(`/resultadorespuestas/${procesoId}`)
          .then(res => {
            this.respuestas = res.data;
          })
          .catch(e => {
            console.log(e.response);
          })
      }
    },
    changeFiltro2(a) {
      if (a == 'Resultado: Todas las respuestas') {
        const procesoId = this.procesoModal.id
        this.axios.get(`/allanswers/${procesoId}`)
          .then(res => {
            this.allanswers = res.data;
          })
          .catch(e => {
            console.log(e.response);
          })
      }
      if (a == 'Resultado: Solo respuestas positivas') {
        const procesoId = this.procesoModal.id
        this.axios.get(`/onlypositive/${procesoId}`)
          .then(res => {
            this.allanswers = res.data;
          })
          .catch(e => {
            console.log(e.response);
          })
      }
      if (a == 'Resultado: Promedio de positivos') {
        const procesoId = this.procesoModal.id
        this.axios.get(`/averagepositive/${procesoId}`)
          .then(res => {
            this.allanswers = res.data;
          })
          .catch(e => {
            console.log(e.response);
          })
      }
    },
    reportGeneralExcel() {
      this.loader2 = false;
      const procesoId = this.procesoModal.id
      this.reportExcel.filter = this.select2
      this.axios.post(`/reportExcel/${procesoId}`, this.reportExcel, { responseType: "blob" })
        // this.axios.post(`/reportExcel/${procesoId}`, this.reportExcel)
        .then(res => {
          // console.log(res.data)
          var fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', this.procesoModal.nombre + '.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
          this.loading2 = false;
          this.loader2 = null;
        })
        .catch(e => {
          this.loading2 = false;
          this.loader2 = null;
          console.log(e.response);
        })
    },
    viewDetails(item) {
      this.fullnameEncuestado = item.nombre + ' ' + item.apellido
      this.axios.post(`/viewDetailsEncuestado`, item)
        .then(res => {
          this.detailsBodyEncuestado = res.data
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    getColor(estado) {
      if (estado == 'Pendiente') return 'red'
      else if (estado == 'En proceso') return 'orange'
      else return 'green'
    },
    cleanArrayEncuestado() {
      this.encuestadosSeleccionados = [];
      this.encuestadosSeleccionados$.next(this.encuestadosSeleccionados);
    },
    activarEdicionIntroductionReport(id) {
      console.log("Searching for: " + id);
      this.axios.get(`/introductionreporte/${id}`)
        .then(res => {
          this.dialog8 = true
          console.log(JSON.stringify(res.data));
          this.introductionReportEditar = res.data;
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    editarIntroductionReport(item) {
      if (this.$refs.formEditIntroductionReport.validate()) {
        this.axios.put(`/introductionreporte/${item._id}`, item)
          .then(res => {
            this.snackbar = true
            this.mensaje.texto = '¡Introducción del reporte guardado!'
            this.dialog8 = false
          })
          .catch(e => {
            console.log(e.response);
          })
      }
    }

  }
}
</script>
