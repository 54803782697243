<template>
  <div>
    <v-card class="flex-grow-1" v-if="video !== undefined">
      <v-card-title>{{ video.titulo }}</v-card-title>
      <v-card-text class="d-flex justify-center">
        <video-player :options="videoOptions" key="videoPlayer" :videoSrc="videoSrc"></video-player>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import VideoPlayer from "../video-player/VideoPlayer.vue";
export default Vue.extend({
  components: { VideoPlayer },
  props: ["video"],
  watch: {
    video(value) {
      this.videoSrc = value.src;
    }
  },
  data() {
    return {
      videoSrc: "videos/VIDEO_1.mp4",
      videoOptions: {
        autoplay: true,
        controls: true,
        sources: [
          {
            src: "videos/VIDEO_1.mp4",
            type: "video/mp4",
          },
        ],
      },
    };
  },
});
</script>