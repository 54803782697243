<template>
    <v-app>
        <v-main>
            <div>{{ mensaje }}</div>
        </v-main>
    </v-app>
</template>

<script>

import {mapActions} from 'vuex'

export default {
    data() {
        return {
			token: '',
			urlDeseada: '',
            mensaje: 'Cargando...',
        }
    },
    props: {
        source: String
    },
    methods: {
        ...mapActions(['guardarUsuario']),
        login(){
			this.axios.post('/auth/sso', { ssoToken: this.token })
				.then(res =>{
					this.guardarUsuario(res.data);
					window.location.href = this.urlDeseada;
				})
				.catch(e=>{
                    this.mensaje = e.response.data.mensaje;
				})
        }
    },
    created() {
		const { url: urlBase64, token } = this.$route.query;
		const urlDeseada = Buffer.from(urlBase64, 'base64').toString('utf8');
		this.token = token;
		this.urlDeseada = urlDeseada;
		this.login();
    },
}
</script>