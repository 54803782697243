<template>
  <v-container grid-list-xl>
    <v-row justify="center">
      <v-col cols="12" md="4" sm="6">
        <v-card class="mb-3 pa-3">
          <v-form @submit.prevent="editarDimension(dimensionEditar)" v-if="editar" ref="formEditDi">
            <v-chip class="ml-0 mt-3" color="#D91C60" label text-color="white">
              <v-list-item-action>
                <v-icon>fas fa-chart-line</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <h3>MODIFICAR DIMENSIÓN</h3>
              </v-list-item-content>
            </v-chip>
            <v-text-field :rules="inputRulesNameDimension" label="Nombre de dimensión" v-model="dimensionEditar.nombre"></v-text-field>
            <v-textarea :rules="inputRulesDescripcionDimension" label="Descripción de dimensión" v-model="dimensionEditar.descripcion"></v-textarea>
            <v-row align="center" justify="space-around" class="mt-3">
              <v-btn rounded color="warning" type="submit" class="ml-10">Modificar</v-btn>
              <v-btn rounded type="submit" class="mr-10"  @click="editar = false; resetDimension()">Cancelar</v-btn>
            </v-row>
          </v-form>

          <v-form @submit.prevent="agregarDimension()" v-if="!editar" ref="formAddDi">
            <v-chip class="ml-0 mt-3" color="#D91C60" label text-color="white">
              <v-list-item-action>
                <v-icon>fas fa-chart-line</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <h3>NUEVA DIMENSIÓN</h3>
              </v-list-item-content>
            </v-chip>
            <v-text-field :rules="inputRulesNameDimension" label="Nombre de dimensión" v-model="dimension.nombre"></v-text-field>
            <v-textarea :rules="inputRulesDescripcionDimension" label="Descripción de dimensión" v-model="dimension.descripcion"></v-textarea>
            <v-btn rounded block color="success" type="submit" class="mt-3">Agregar</v-btn>
          </v-form>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="8" >
        <v-card>
          <v-card-title >
            <v-chip color="#D91C60" label text-color="white">
              <v-icon left>widgets</v-icon>
              <h3>LISTA DIMENSIONES</h3>
            </v-chip>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar dimensión"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table fixed-header height="350px" :headers="headers" :items="dimensiones" :search="search" sort-by="id" class="elevation-1" :items-per-page="5">
            <template v-slot:item.accion="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :disabled="item.disabled ? true : false" class="mr-2" fab x-small color="success" @click="activarEdicion(item._id)" v-bind="attrs" v-on="on">
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar dimensión</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :disabled="item.disabled ? true : false" class="mr-2" fab x-small color="error" @click="eliminarDimension(item._id)" v-bind="attrs" v-on="on">
                    <v-icon dark>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar dimensión</span>
              </v-tooltip>
            </template>
            <template slot="no-data">
              No se encontró ningún registro
            </template>
          </v-data-table>
        </v-card>
      </v-col>

    </v-row>

    <v-snackbar v-model="snackbar">
      {{ mensaje.texto }}
      <template>
        <v-btn
        color="blue"
        text
        @click="snackbar = false"
        class="ml-16">
            Cerrar
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>

  import {mapState} from 'vuex'

  export default {
    data() {
      return {
        dimensiones: [],
        mensaje: {color: '', texto: ''},
        dimension: {
            nombre: '',
            descripcion: ''
        },
        editar: false,
        dimensionEditar: {},
        search: '', //para el cuadro de búsqueda de datatables
        snackbar: false, //para el mensaje del snackbar
        headers: [
          // { text: 'ID', align: 'left', sortable: false,value: '_id'},
          { text: 'DIMENSIÓN', value:'nombre', width: '25%'},
          { text: 'DESCRIPCIÓN', value: 'descripcion', width: '55%'},
          { text: 'ACCIONES', value: 'accion', sortable: false },
        ],
        inputRulesNameDimension: [
          v => !!v || 'Nombre de dimensión es requerido'
        ],
        inputRulesDescripcionDimension: [
          v => !!v || 'Descripción de dimensión es requerido',
          v => (v && v.length >= 5) || 'Descripción de dimensión debe de ser mayor a 5 caracteres'
        ]
      }
    },
    computed: {
      ...mapState(['token']),
      cantidadPaginas(){
        return Math.ceil(this.totalDimensiones / this.limite)
      }
    },
    created() {
        this.listarDimensiones();
    },
    methods: {
      agregarDimension(){
       if(this.$refs.formAddDi.validate()){
          this.axios.post('/nueva-dimension', this.dimension)
            .then(res=> {
              console.log(res.data);
              this.dimensiones.push(res.data)
              this.dimension.nombre = ''
              this.dimension.descripcion = ''
              this.snackbar = true
              this.mensaje.texto = '¡Dimensión agregada!'
              this.listarDimensiones();
              this.$refs.formAddDi.reset()
            })
            .catch(e=>{
              console.log(e.response);
              if(e.response.data.error.errors.nombre.message){
                this.snackbar = true
                this.mensaje.texto = e.response.data.error.errors.nombre.message
              }else{
                this.snackbar = true
                this.mensaje.texto = 'Error de sistema'
              }
            })
        }
      },
      activarEdicion(id){
        this.editar = true;
        console.log(id);
        this.axios.get(`/dimension/${id}`)
          .then(res => {
              this.dimensionEditar = res.data;
          })
          .catch(e => {
            console.log(e.response);
          })
      },
      eliminarDimension(id){
        this.$confirm('¿Deseas eliminar la dimensión?', { title: 'Alerta' })
          .then(res => {
            if (res == true) {
              this.axios.delete(`/dimension/${id}`)
              .then(res => {
                const index = this.dimensiones.findIndex(item => item._id === res.data._id);
                this.dimensiones.splice(index,1);
                this.mensaje.color = 'success';
                this.snackbar = true
                this.mensaje.texto = '¡Dimensión eliminada!';
                this.listarDimensiones();
              })
              .catch(e=> {
                this.snackbar = true
                this.mensaje.texto = '¡Esta dimensión está siendo usado en una encuesta!';
                console.log(e.response);
              })
            }
          })
      },
      editarDimension(item){
        if(this.$refs.formEditDi.validate()){
          this.axios.put(`/dimension/${item._id}`, item)
            .then(res => {
              const index = this.dimensiones.findIndex(n => n._id === res.data._id);
              this.dimensiones[index].nombre = res.data.nombre;
              this.dimensiones[index].descripcion = res.data.descripcion;
              this.mensaje.color = 'success';
              this.snackbar = true
              this.mensaje.texto = '¡Dimensión modificada!';
              this.editar = false;
              this.listarDimensiones();
              this.$refs.formEditDi.reset()
            })
            .catch(e=> {
              this.editar = false;
              this.snackbar = true
              this.mensaje.texto = '¡Esta dimensión está siendo usado en una encuesta!';
              console.log(e.response);
            })
        }
      },
      listarDimensiones(){
        this.axios('/dimensiones').then(response =>{
          this.dimensiones = response.data.arrayDimensiones;
          this.dimensiones.sort((a,b) => new Date(b.date) - new Date(a.date));
        });
      },
      resetDimension(){
        this.$refs.formEditDi.reset()
      }
    },
    props: {
      popup: Function
    }

  }
</script>
