<template>
  <div class="categoria">
    <h4 class="categoria--titulo prevent-select" @click="toggleCategory">
      <span>
        <v-icon size="15px">{{ category.icon }}</v-icon>
        {{ category.nombre }}
      </span>
      <v-icon
        class="categoria--titulo--icon"
        :class="{ 'categoria--titulo--icon__active': show }"
        >mdi-chevron-down</v-icon
      >
    </h4>
    <v-list v-if="show" dense>
      <v-list-item-group color="primary" @change="pickVideo">
        <v-list-item
          v-for="(video, i) of category.videos"
          :key="`categoria ${category.nombre} video ${i}`"
          two-line
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ video.titulo }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ video.duration }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<style scoped>
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.categoria {
  margin-bottom: 4px;
}

.categoria--titulo {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  height: 34px;
  align-items: baseline;
}

.categoria--titulo--icon {
  transition: all 0.3s ease;
}

.categoria--titulo--icon__active {
  transform: rotateZ(-180deg);
}
</style>

<script>
import { consoleError } from 'vuetify/lib/util/console';

export default {
  data() {
    return {
      show: false,
    };
  },
  props: {
    category: Object,
    categoryId: Number,
    modelValue: Number,
    selectedCategory: Number,
  },
  inject: ['selectCategory', 'selectVideo'],
  watch: {
    selectedCategory(value) {
      console.log(value);
      if(value !== this.categoryId) {
        this.show = false;
      }
    }
  },
  methods: {
    toggleCategory(){
      this.show = !this.show;
      if(this.show) {
        this.selectCategory(this.categoryId);
      }
    },
    pickVideo(value) {
      this.selectVideo(this.categoryId, value);
    }
  }
};
</script>