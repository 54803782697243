<template>
  <div>
    <v-card max-width="300">
      <v-card-title>Videos Tutoriales</v-card-title>
      <v-card-text>
        <ayuda-menu-categoria
          v-for="(category, i) in categories"
          :key="i"
          :category="category"
          :categoryId="i"
          :v-if="categories.length > 0"
          :selectedCategory="selectedCategory"
        ></ayuda-menu-categoria>
      </v-card-text>
    </v-card>
    <v-card max-width="300" class="mt-4">
      <button class="text-overline download-button" @click="downloadManual">
        <h4><v-icon>mdi-download</v-icon> Descargar Manual</h4>
      </button>
    </v-card>
  </div>
</template>

<style scoped>
.download-button {
  padding: 16px;
}

.download-button h4 {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.0071428571em;
}
</style>

<script>
import AyudaMenuCategoria from "./categorias/AyudaMenuCategoria.vue";
export default {
  components: {
    AyudaMenuCategoria,
  },
  inject: ["getCategories"],
  props: ["selectedCategory"],
  data() {
    return {
      categories: [],
    };
  },
  methods: {
    load() {
      this.categories = this.getCategories();
    },
    downloadManual() {
      this.axios
        .get(`/downloadManual`, { responseType: "arraybuffer" })
        .then((res) => {
          let blob = new Blob([res.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Manual_Surveys.pdf";
          link.click();
        })
        .catch((e) => {
          console.log(e.response);
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>