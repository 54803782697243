<template>
  <v-container grid-list-xl>
    <v-row justify="center">
      <v-col cols="12" md="4" sm="6">
        <v-card class="mb-3 pa-3">

          <v-form @submit.prevent="agregarEncuesta()" v-if="!editar" ref="formAddEncuesta">
            <v-chip class="ml-0 mt-3" color="#27D9D2" label text-color="white">
              <v-list-item-action>
                <v-icon>fas fa-chart-pie</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <h3>NUEVA ENCUESTA</h3>
              </v-list-item-content>
            </v-chip>
            <v-text-field :rules="inputRulesNameEncuesta" label="Nombre de encuesta" v-model="encuesta.nombre">
            </v-text-field>
            <v-radio-group v-model="radios" row :rules="inputRulesOptionEncuesta">
              <v-radio label="5 Opciones" value="5" color="#298A45"></v-radio>
              <v-radio label="6 Opciones" value="6" color="#298A45"></v-radio>
            </v-radio-group>
            <div v-if="radios == '5'">
              <v-text-field autocomplete="off" label="Opción 1 (Puede ser editable)" v-model="fiveoptions.opcion1">
              </v-text-field>
              <v-text-field autocomplete="off" label="Opción 2 (Puede ser editable)" v-model="fiveoptions.opcion2">
              </v-text-field>
              <v-text-field autocomplete="off" label="Opción 3 (Puede ser editable)" v-model="fiveoptions.opcion3">
              </v-text-field>
              <v-text-field autocomplete="off" label="Opción 4 (Puede ser editable)" v-model="fiveoptions.opcion4">
              </v-text-field>
              <v-text-field autocomplete="off" label="Opción 5 (Puede ser editable)" v-model="fiveoptions.opcion5">
              </v-text-field>
            </div>
            <div v-if="radios == '6'">
              <v-text-field autocomplete="off" label="Opción 1 (Puede ser editable)" v-model="sixoptions.opcion1">
              </v-text-field>
              <v-text-field autocomplete="off" label="Opción 2 (Puede ser editable)" v-model="sixoptions.opcion2">
              </v-text-field>
              <v-text-field autocomplete="off" label="Opción 3 (Puede ser editable)" v-model="sixoptions.opcion3">
              </v-text-field>
              <v-text-field autocomplete="off" label="Opción 4 (Puede ser editable)" v-model="sixoptions.opcion4">
              </v-text-field>
              <v-text-field autocomplete="off" label="Opción 5 (Puede ser editable)" v-model="sixoptions.opcion5">
              </v-text-field>
              <v-text-field autocomplete="off" label="Opción 6 (Puede ser editable)" v-model="sixoptions.opcion6">
              </v-text-field>
            </div>

            <v-btn rounded block color="success" type="submit" class="mt-3">Agregar</v-btn>
          </v-form>

          <v-form @submit.prevent="editarEncuesta(encuestaEditar)" v-if="editar" ref="formEditEncuesta">
            <v-chip class="ml-0 mt-3" color="#27D9D2" label text-color="white">
              <v-list-item-action>
                <v-icon>fas fa-chart-pie</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <h3>MODIFICAR ENCUESTA</h3>
              </v-list-item-content>
            </v-chip>
            <v-text-field :rules="inputRulesNameEncuesta" label="Nombre de encuesta" v-model="encuestaEditar.nombre">
            </v-text-field>
            <v-radio-group v-model="encuestaEditar.numopciones" row>
              <v-radio label="5 Opciones" value="5" color="#298A45"></v-radio>
              <v-radio label="6 Opciones" value="6" color="#298A45"></v-radio>
            </v-radio-group>
            <div v-if="encuestaEditar.numopciones == '5'">
              <v-text-field autocomplete="off" label="Total desacuerdo" v-model="encuestaEditar.opcion1"></v-text-field>
              <v-text-field autocomplete="off" label="En desacuerdo" v-model="encuestaEditar.opcion2"></v-text-field>
              <v-text-field autocomplete="off" label="Neutral" v-model="encuestaEditar.opcion3"></v-text-field>
              <v-text-field autocomplete="off" label="En acuerdo" v-model="encuestaEditar.opcion4"></v-text-field>
              <v-text-field autocomplete="off" label="Total acuerdo" v-model="encuestaEditar.opcion5"></v-text-field>
            </div>
            <div v-if="encuestaEditar.numopciones == '6'">
              <v-text-field autocomplete="off" label="Total desacuerdo" v-model="encuestaEditar.opcion1"></v-text-field>
              <v-text-field autocomplete="off" label="En desacuerdo" v-model="encuestaEditar.opcion2"></v-text-field>
              <v-text-field autocomplete="off" label="Ligero desacuerdo" v-model="encuestaEditar.opcion3">
              </v-text-field>
              <v-text-field autocomplete="off" label="Ligero acuerdo" v-model="encuestaEditar.opcion4"></v-text-field>
              <v-text-field autocomplete="off" label="En acuerdo" v-model="encuestaEditar.opcion5"></v-text-field>
              <v-text-field autocomplete="off" label="Total acuerdo" v-model="encuestaEditar.opcion6"></v-text-field>
            </div>

            <v-row align="center" justify="space-around" class="mt-3">
              <v-btn rounded color="warning" type="submit" class="ml-10">Modificar</v-btn>
              <v-btn rounded type="submit" class="mr-10" @click="editar = false; resetEncuesta()">Cancelar</v-btn>
            </v-row>

          </v-form>

        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="8">
        <v-card>
          <v-card-title>
            <v-chip color="#27D9D2" label text-color="white">
              <v-icon left>widgets</v-icon>
              <h3>LISTA ENCUESTAS</h3>
            </v-chip>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar encuesta" single-line hide-details>
            </v-text-field>
          </v-card-title>
          <v-data-table fixed-header height="350px" :headers="dataEncuestas" :items="encuestas" :search="search"
            sort-by="id" class="elevation-1" :items-per-page="5">
            <template v-slot:item.accion="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :disabled="item.disabled ? true : false" class="mr-2" fab x-small color="success"
                    @click="activarEdicion(item._id)" v-bind="attrs" v-on="on">
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar encuesta</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :disabled="item.disabled ? true : false" class="mr-2" fab x-small color="error"
                    @click="eliminarEncuesta(item._id)" v-bind="attrs" v-on="on">
                    <v-icon dark>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar encuesta</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-2" fab x-small color="warning"
                    @click="dialog = true; e1 = 1; modalDemografia(item); getEncuestaDemografia(item._id)"
                    v-bind="attrs" v-on="on">
                    <v-icon dark>mdi-view-list</v-icon>
                  </v-btn>
                </template>
                <span>Gestionar encuesta</span>
              </v-tooltip>
            </template>
            <template slot="no-data">
              No se encontró ningún registro
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable persistent>
      <v-card tile>
        <v-toolbar flat dark color="#298A45">
          <v-btn icon dark @click="validateStatusEncuesta(encuestaeditado.id)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Configurar encuesta - {{encuestaeditado.nombre}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="validateStatusEncuesta(encuestaeditado.id)">
              Guardar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-stepper non-linear v-model="e1">
            <v-stepper-header>
              <v-stepper-step editable step="1" color="#298A45">
                Datos demográficos
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step editable step="2" color="#298A45" @click=" getEncuestaDimension(encuestaeditado.id)">
                Dimensiones
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step editable step="3" color="#298A45"
                @click=" getEncuestaPreguntas(encuestaeditado.id), getEncuestaDimension(encuestaeditado.id)">
                Preguntas
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>

              <v-stepper-content step="1">
                <div class="mb-15" style="height: 325px">
                  <div class="mb-2">
                    <span class="ml-4"><span class="text-subtitle-1 green--text text--darken-3">Aviso: </span>
                      Selecciona al menos un dato demográfico</span>
                  </div>
                  <form @submit.prevent="agregarEncuestaDemografia(encuestaeditado.id)">
                    <v-simple-table fixed-header height="300px">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">DATO DEMOGRÁFICO</th>
                            <th class="text-left">ACCIONES</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in demografias" :key="index">
                            <td width="95%">{{item.nombre}}
                              <v-tooltip right max-width="200">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon color="indigo" v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{item.descripcion}}</span>
                              </v-tooltip>
                            </td>
                            <td width="5%">
                              <v-checkbox v-model="encuestademografia.demografiaId" color="#298A45" :value="item._id" @click="selectDatoDemografico(item._id)">
                              </v-checkbox>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-row justify='end'>
                      <v-col class="text-right">
                        <v-btn color="success" rounded type="submit">
                          Guardar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </form>
                </div>
              </v-stepper-content>

              <v-stepper-content step="2">
                <div class="mb-15" style="height: 325px">
                  <div class="mb-2">
                    <span class="ml-4"><span class="text-subtitle-1 green--text text--darken-3">Aviso: </span>
                      Selecciona al menos una dimensión</span>
                  </div>
                  <form @submit.prevent="agregarEncuestaDimension(elementDimension)">
                    <v-simple-table fixed-header height="300px">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">DIMENSION</th>
                            <th class="text-left">ACCIONES</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in dimensiones" :key="index">
                            <td width="95%">{{item.nombre}}
                              <v-tooltip right max-width="200">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon color="indigo" v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{item.descripcion}}</span>
                              </v-tooltip>
                            </td>
                            <td width="5%">
                              <v-checkbox v-model="elementDimension.dimensionId" color="#298A45" :value="item._id" @click="selectDimension(itemId)">
                              </v-checkbox>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-row justify='end'>
                      <v-col class="text-right">
                        <v-btn color="success" rounded type="submit">
                          Guardar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </form>
                </div>

              </v-stepper-content>

              <v-stepper-content step="3">
                <div class="mb-2">
                  <span><span class="text-subtitle-1 green--text text--darken-3">Aviso: </span> Agregue sus preguntas,
                    es necesario que todas las dimensiones seleccionadas en el PASO 2 tengan al menos una pregunta
                    cerrada</span>
                </div>
                <v-row justify="center">
                  <v-col cols="12" md="6" sm="6">
                    <v-card>
                      <v-card-title>
                        <v-chip color="error lighten-2" label text-color="white">
                          <v-icon left>mdi-lock</v-icon>
                          <h3>PREGUNTAS CERRADAS</h3>
                        </v-chip>
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search2" append-icon="mdi-magnify" label="Buscar pregunta cerrada"
                          single-line hide-details></v-text-field>
                      </v-card-title>

                      <v-data-table fixed-header height="180px" :headers="dataPreguntasCerradas"
                        :items="preguntasCerradas" :search="search2" sort-by="id" class="elevation-1"
                        :items-per-page="5">
                        <template v-slot:item.accion="{item}">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn :disabled="item.disabled ? true : false" class="mr-2" fab x-small color="success"
                                @click="activarEdicionEncuestaPregunta(item._id); opcionesEncuestaPregunta(encuestaeditado.id)"
                                v-bind="attrs" v-on="on">
                                <v-icon dark>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Editar pregunta</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn :disabled="item.disabled ? true : false" class="mr-2" fab x-small color="error"
                                @click="deleteEncuestaPregunta(item._id)" v-bind="attrs" v-on="on">
                                <v-icon dark>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Eliminar pregunta</span>
                          </v-tooltip>
                        </template>
                        <template slot="no-data">
                          No se encontró ningún registro
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <v-card>
                      <v-card-title>
                        <v-chip color="orange lighten-2" label text-color="white">
                          <v-icon left>mdi-lock-open-outline</v-icon>
                          <h3>PREGUNTAS ABIERTAS</h3>
                        </v-chip>
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search3" append-icon="mdi-magnify" label="Buscar pregunta abierta"
                          single-line hide-details></v-text-field>
                      </v-card-title>

                      <v-data-table fixed-header height="180px" :headers="dataPreguntasAbiertas"
                        :items="preguntasAbiertas" :search="search3" sort-by="id" class="elevation-1"
                        :items-per-page="5">
                        <template v-slot:item.accion="{item}">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn :disabled="item.disabled ? true : false" class="mr-2" fab x-small color="success"
                                @click="activarEdicionEncuestaPregunta(item._id); opcionesEncuestaPregunta(encuestaeditado.id)"
                                v-bind="attrs" v-on="on">
                                <v-icon dark>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Editar pregunta</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn :disabled="item.disabled ? true : false" class="mr-2" fab x-small color="error"
                                @click="deleteEncuestaPregunta(item._id)" v-bind="attrs" v-on="on">
                                <v-icon dark>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Eliminar pregunta</span>
                          </v-tooltip>

                        </template>
                        <template slot="no-data">
                          No se encontró ningún registro
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6" md="9">
                    <v-btn outlined color="indigo" class="text-capitalize" bottom
                      @click="dialog3 = !dialog3; editarPregunta=false; opcionesEncuestaPregunta(encuestaeditado.id)">
                      Agregar pregunta
                      <v-icon color="indigo" fab medium class="ml-3">mdi-plus-circle</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-dialog v-model="dialog3" persistent max-width="600px">
                    <v-card>
                      <v-form @submit.prevent="agregarEncuestaPregunta()" v-if="!editarPregunta" ref="formAddPregunta">
                        <v-card-title>
                          <span class="headline">Agregar Pregunta</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-text-field :rules="inputRulesNamePregunta" label="Escribe tu pregunta"
                              v-model="preguntaEncuesta.pregunta"></v-text-field>
                            <div class="mt-1">Tipo de pregunta</div>
                            <v-radio-group v-model="preguntaEncuesta.tipoPregunta" row :rules="inputRulesOptionPregunta"
                              class="mt-0">
                              <v-radio label="Cerrada" value="cerrada" color="#298A45"></v-radio>
                              <v-radio label="Abierta" value="abierta" color="#298A45"></v-radio>
                            </v-radio-group>
                            <div v-if="preguntaEncuesta.tipoPregunta == 'cerrada'">
                              <v-select v-model="preguntaEncuesta.dimension" :items="preguntaDimension"
                                label="Dimensión" dense :rules="inputRulesSelectPregunta"></v-select>
                            </div>
                            <!-- <div v-if="preguntaEncuesta.tipoPregunta == 'cerrada'">
                                  <div v-if="encuestaeditado.numopciones == '5 Opciones'">
                                    <v-text-field label="Opción1" disabled v-model="encuestaPreguntaOpciones.opcion1"></v-text-field>
                                    <v-text-field label="Opción2" disabled v-model="encuestaPreguntaOpciones.opcion2"></v-text-field>
                                    <v-text-field label="Opción3" disabled v-model="encuestaPreguntaOpciones.opcion3"></v-text-field>
                                    <v-text-field label="Opción4" disabled v-model="encuestaPreguntaOpciones.opcion4"></v-text-field>
                                    <v-text-field label="Opción5" disabled v-model="encuestaPreguntaOpciones.opcion5"></v-text-field>
                                </div>
                                <div v-else>
                                    <v-text-field label="Opción1" disabled v-model="encuestaPreguntaOpciones.opcion1"></v-text-field>
                                    <v-text-field label="Opción2" disabled v-model="encuestaPreguntaOpciones.opcion2"></v-text-field>
                                    <v-text-field label="Opción3" disabled v-model="encuestaPreguntaOpciones.opcion3"></v-text-field>
                                    <v-text-field label="Opción4" disabled v-model="encuestaPreguntaOpciones.opcion4"></v-text-field>
                                    <v-text-field label="Opción5" disabled v-model="encuestaPreguntaOpciones.opcion5"></v-text-field>
                                    <v-text-field label="Opción6" disabled v-model="encuestaPreguntaOpciones.opcion6"></v-text-field>
                                </div>
                              </div> -->
                            <div v-if="preguntaEncuesta.tipoPregunta == 'abierta'">
                              <v-textarea filled auto-grow label="La respuesta del encuestado es en texto" rows="2"
                                row-height="20" disabled></v-textarea>
                            </div>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="success" rounded type="submit">
                            Agregar
                          </v-btn>
                          <v-btn rounded @click="dialog3 = false; resetPreguntaAdd()">
                            Cancelar
                          </v-btn>
                        </v-card-actions>
                      </v-form>

                      <v-form @submit.prevent="editarEncuestaPregunta(encuestaPreguntaEditar)" v-if="editarPregunta"
                        ref="formEditPregunta">
                        <v-card-title>
                          <span class="headline">Editar Pregunta</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-text-field :rules="inputRulesNamePregunta" label="Escribe tu pregunta"
                              v-model="encuestaPreguntaEditar.pregunta"></v-text-field>
                            <div>Tipo de pregunta</div>
                            <v-radio-group v-model="encuestaPreguntaEditar.tipoPregunta" row
                              :rules="inputRulesOptionPregunta" class="mt-0">
                              <v-radio label="Cerrada" value="cerrada" color="#298A45"></v-radio>
                              <v-radio label="Abierta" value="abierta" color="#298A45"></v-radio>
                            </v-radio-group>
                            <div v-if="encuestaPreguntaEditar.tipoPregunta == 'cerrada'">
                              <v-select v-model="encuestaPreguntaEditar.dimension" :items="preguntaDimension"
                                label="Dimensión" dense :rules="inputRulesSelectPregunta" class="mt-3"></v-select>
                            </div>
                            <!-- <div v-if="encuestaPreguntaEditar.tipoPregunta == 'cerrada'">
                                  <div v-if="encuestaeditado.numopciones == '5 Opciones'">
                                    <v-text-field label="Opción1" disabled v-model="encuestaPreguntaOpciones.opcion1"></v-text-field>
                                    <v-text-field label="Opción2" disabled v-model="encuestaPreguntaOpciones.opcion2"></v-text-field>
                                    <v-text-field label="Opción3" disabled v-model="encuestaPreguntaOpciones.opcion3"></v-text-field>
                                    <v-text-field label="Opción4" disabled v-model="encuestaPreguntaOpciones.opcion4"></v-text-field>
                                    <v-text-field label="Opción5" disabled v-model="encuestaPreguntaOpciones.opcion5"></v-text-field>
                                </div>
                                <div v-else>
                                    <v-text-field label="Opción1" disabled v-model="encuestaPreguntaOpciones.opcion1"></v-text-field>
                                    <v-text-field label="Opción2" disabled v-model="encuestaPreguntaOpciones.opcion2"></v-text-field>
                                    <v-text-field label="Opción3" disabled v-model="encuestaPreguntaOpciones.opcion3"></v-text-field>
                                    <v-text-field label="Opción4" disabled v-model="encuestaPreguntaOpciones.opcion4"></v-text-field>
                                    <v-text-field label="Opción5" disabled v-model="encuestaPreguntaOpciones.opcion5"></v-text-field>
                                    <v-text-field label="Opción6" disabled v-model="encuestaPreguntaOpciones.opcion6"></v-text-field>
                                </div>
                              </div> -->
                            <div v-if="encuestaPreguntaEditar.tipoPregunta == 'abierta'">
                              <v-textarea filled auto-grow label="La respuesta del encuestado es en texto" rows="2"
                                row-height="20" disabled></v-textarea>
                            </div>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="warning" rounded type="submit">
                            MODIFICAR
                          </v-btn>
                          <v-btn rounded @click="dialog3 = false; resetPreguntaEdit()">
                            CANCELAR
                          </v-btn>
                        </v-card-actions>
                      </v-form>
                    </v-card>
                  </v-dialog>
                </v-row>

              </v-stepper-content>

            </v-stepper-items>

          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog4" persistent max-width="460">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="1">
              <v-icon large color="orange">mdi-alert-circle-outline</v-icon>
            </v-col>
            <v-col cols="6" md="11" class="pl-2">
              <span class="text-h4 orange--text">Nota</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6" md="12" class="pl-2 my-2 body-2">
              Para que la encuesta se encuentre ACTIVA, asegúrese de:
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="1">
              <v-icon class="ml-3" small color="green">mdi-check</v-icon>
            </v-col>
            <v-col cols="6" md="11" class="pl-2 caption">
              Seleccionar al menos un dato demográfico
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="1">
              <v-icon class="ml-3" small color="green">mdi-check</v-icon>
            </v-col>
            <v-col cols="6" md="11" class="pl-2 caption">
              Seleccionar al menos una dimensión
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="1">
              <v-icon class="ml-3" small color="green">mdi-check</v-icon>
            </v-col>
            <v-col cols="6" md="11" class="pl-2 caption">
              Agregar preguntas, es necesario que todas las dimensiones estén relacionadas al menos a una pregunta
              cerrada
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="align-center justify-center mx-auto">
          <v-btn color="green" outlined class="text-capitalize" @click="dialog4 = false; ">
            Revisar
          </v-btn>
          <v-btn color="red" outlined class="text-capitalize" @click="dialog = false; dialog4 = false">
            Finalizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar">
      {{ mensaje.texto }}
      <template>
        <v-btn color="blue" text @click="snackbar = false" class="ml-16">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>

import { mapState } from 'vuex'

export default {
  data() {
    return {
      agregarPreguntaLoading: false,
      dialog4: false,
      e1: 1,
      radios: null,
      fiveoptions: {
        opcion1: 'Total desacuerdo',
        opcion2: 'En desacuerdo',
        opcion3: 'Neutral',
        opcion4: 'En acuerdo',
        opcion5: 'Total acuerdo'
      },
      sixoptions: {
        opcion1: 'Total desacuerdo',
        opcion2: 'En desacuerdo',
        opcion3: 'Ligero desacuerdo',
        opcion4: 'Ligero acuerdo',
        opcion5: 'En acuerdo',
        opcion6: 'Total acuerdo'
      },
      encuestas: [],
      editar: false,
      mensaje: { color: '', texto: '' },
      encuestaEditar: {},
      search: '', //para el cuadro de búsqueda de datatables
      snackbar: false, //para el mensaje del snackbar
      dataEncuestas: [
        // { text: 'ID', align: 'left', sortable: false,value: '_id'},
        { text: 'ENCUESTA', value: 'nombre', width: '40%' },
        { text: '# OPCIONES', value: 'numopciones' },
        { text: 'ESTADO', value: 'estado' },
        { text: 'ACCIONES', value: 'accion', sortable: false },
      ],
      encuestaeditado: {
        id: '',
        nombre: '',
        numopciones: ''
      },
      encuesta: {
        nombre: '',
        numopciones: '',
        opcion1: '',
        opcion2: '',
        opcion3: '',
        opcion4: '',
        opcion5: ''
      },
      encuestaBody: '',
      dialog: false,
      e1: 1,
      inputRulesNameEncuesta: [
        v => !!v || 'Nombre de encuesta es requerido'
      ],
      inputRulesOptionEncuesta: [
        v => !!v || 'Debe seleccionar una opción'
      ],
      //STEP 1
      demografias: [],
      dimensiones: [],
      singleSelect: false,
      demografiaId: [],
      encuestademografia: {
        demografiaId: []
      },
      //STEP 2
      encuestadimension: [],
      elementDimension: {
        dimensionId: []
      },
      dialog2: false,
      encuestadimensionAdd: {
        dimensionId: '',
        dimension: '',
        dimensionPuntaje: 0,
        encuestaId: ''
      },
      //STEP 3
      // radiosP: null,
      dialog3: false,
      preguntaDimension: [],
      editarPregunta: false,
      preguntaEncuesta: {
        pregunta: '',
        tipoPregunta: null,
        encuestaId: '',
        dimension: ''
      },
      preguntasAbiertas: [],
      preguntasCerradas: [],
      dataPreguntasCerradas: [
        // { text: 'ID', align: 'left', sortable: false,value: '_id'},
        { text: 'PREGUNTA', value: 'pregunta', width: '55%' },
        { text: 'DIMENSIÓN', value: 'dimension', width: '20%' },
        { text: 'ACCIONES', value: 'accion', sortable: false },
      ],
      dataPreguntasAbiertas: [
        // { text: 'ID', align: 'left', sortable: false,value: '_id'},
        { text: 'PREGUNTA', value: 'pregunta', width: '75%' },
        // { text: 'DIMENSIÓN', value:'dimension', width:'20%'},
        { text: 'ACCIONES', value: 'accion', sortable: false },
      ],
      search2: '',
      search3: '',
      encuestaPreguntaEditar: {},
      encuestaPreguntaOpciones: [],
      inputRulesNamePregunta: [
        v => !!v || 'Escribe tu pregunta'
      ],
      inputRulesSelectPregunta: [
        v => !!v || 'Debe seleccionar una dimensión'
      ],
      inputRulesOptionPregunta: [
        v => !!v || 'Debe seleccionar un tipo de opción'
      ],
    }
  },
  computed: {
    ...mapState(['token']),
  },
  created() {
    this.listarEncuestas();
  },
  methods: {
    agregarEncuesta() {
      if (this.$refs.formAddEncuesta.validate()) {
        if (this.radios == '5') {
          this.encuestaBody = this.encuesta;
          this.encuestaBody.numopciones = this.radios;
          this.encuestaBody.opcion1 = this.fiveoptions.opcion1;
          this.encuestaBody.opcion2 = this.fiveoptions.opcion2;
          this.encuestaBody.opcion3 = this.fiveoptions.opcion3;
          this.encuestaBody.opcion4 = this.fiveoptions.opcion4;
          this.encuestaBody.opcion5 = this.fiveoptions.opcion5;
          delete this.encuestaBody.opcion6;
        } else {
          this.encuestaBody = this.encuesta;
          this.encuestaBody.numopciones = this.radios;
          this.encuestaBody.opcion1 = this.sixoptions.opcion1;
          this.encuestaBody.opcion2 = this.sixoptions.opcion2;
          this.encuestaBody.opcion3 = this.sixoptions.opcion3;
          this.encuestaBody.opcion4 = this.sixoptions.opcion4;
          this.encuestaBody.opcion5 = this.sixoptions.opcion5;
          this.encuestaBody.opcion6 = this.sixoptions.opcion6;
          this.encuestaBody.valor6 = '6';
        }

        this.axios.post('/nueva-encuesta', this.encuestaBody)
          .then(res => {
            this.encuestas.push(res.data)
            this.encuesta.nombre = ''
            if (this.radios == '5') {
              this.fiveoptions.opcion1 = 'Total desacuerdo'
              this.fiveoptions.opcion2 = 'En desacuerdo'
              this.fiveoptions.opcion3 = 'Neutral'
              this.fiveoptions.opcion4 = 'En acuerdo'
              this.fiveoptions.opcion5 = 'Total acuerdo'
            } else {
              this.sixoptions.opcion1 = 'Total desacuerdo'
              this.sixoptions.opcion2 = 'En desacuerdo'
              this.sixoptions.opcion3 = 'Ligero desacuerdo'
              this.sixoptions.opcion4 = 'Ligero acuerdo'
              this.sixoptions.opcion5 = 'En acuerdo'
              this.sixoptions.opcion6 = 'Total acuerdo'
            }
            this.radios = ''
            this.snackbar = true
            this.mensaje.texto = '¡Encuesta agregada!'
            this.listarEncuestas();
            this.$refs.formAddEncuesta.reset()
          })
          .catch(e => {
            if (e.response.data.error.errors.nombre.message) {
              this.snackbar = true
              this.mensaje.texto = e.response.data.error.errors.nombre.message
            } else {
              this.snackbar = true
              this.mensaje.texto = 'Error de sistema'
            }

          })
      }
    },
    activarEdicion(id) {
      this.editar = true;
      console.log(id);
      this.axios.get(`/encuesta/${id}`)
        .then(res => {
          this.encuestaEditar = res.data;
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    editarEncuesta(item) {
      if (this.$refs.formEditEncuesta.validate()) {
        const recibeEncuesta = item;
        recibeEncuesta.valor6 = '6';
        this.axios.put(`/encuesta/${item._id}`, recibeEncuesta)
          .then(res => {
            const index = this.encuestas.findIndex(n => n._id === res.data._id);
            this.encuestas[index].nombre = res.data.nombre;
            this.encuestas[index].numopciones = res.data.numopciones;
            this.snackbar = true
            this.mensaje.texto = 'Encuesta modificada!';
            this.editar = false;
            this.listarEncuestas();
            this.$refs.formEditEncuesta.reset()
          })
          .catch(e => {
            this.editar = false;
            this.snackbar = true
            this.mensaje.texto = '¡Esta encuesta está siendo usado en un proceso!';
            console.log(e.response);
          })
      }
    },
    listarEncuestas() {
      this.axios('/encuestas').then(response => {
        this.encuestas = response.data;
        this.encuestas.sort((a, b) => new Date(b.date) - new Date(a.date));
      });
    },
    eliminarEncuesta(id) {
      this.$confirm('¿Deseas eliminar la encuesta?', { title: 'Alerta' })
        .then(res => {
          if (res == true) {
            this.axios.delete(`/encuesta/${id}`)
              .then(res => {
                const index = this.encuestas.findIndex(item => item._id === res.data._id);
                this.encuestas.splice(index, 1);
                this.mensaje.color = 'success';
                this.snackbar = true
                this.mensaje.texto = '¡Encuesta eliminada!';
                this.listarEncuestas();
              })
              .catch(e => {
                this.snackbar = true
                this.mensaje.texto = '¡Esta encuesta está siendo usado en un proceso!';
                console.log(e.response);
              })
          }
        })
    },
    modalDemografia(item) {
      console.log(item);
      this.encuestaeditado.id = item._id;
      this.encuestaeditado.nombre = item.nombre;
      this.encuestaeditado.numopciones = item.numopciones;
      this.axios('/demografiasByStatus').then(response => {
        this.demografias = response.data.demografiasDB;
        this.demografias.sort((a, b) => new Date(b.date) - new Date(a.date));
      });
      this.axios('/dimensiones').then(response => {
        this.dimensiones = response.data.arrayDimensiones;
        this.dimensiones.sort((a, b) => new Date(b.date) - new Date(a.date));
      });
    },
    resetEncuesta() {
      this.$refs.formEditEncuesta.reset()
    },
    //STEP 1
    agregarEncuestaDemografia(encuestademografiaId) {
      if (this.encuestademografia.demografiaId.length == 0) {
        this.snackbar = true
        this.mensaje.texto = '¡Seleccione algún dato demográfico!'
      } else {
        const cuerpoEncDemo = this.encuestademografia;
        cuerpoEncDemo.encuestaId = encuestademografiaId;
        this.axios.post('/nueva-encuestademografias', cuerpoEncDemo)
          .then(res => {
            this.snackbar = true
            this.mensaje.texto = '¡Dato demográfico configurado!'
          })
          .catch(e => {
            this.snackbar = true
            this.mensaje.texto = '¡La encuesta no puede ser modificada, esta siendo usada en un proceso!';
            console.log(e.response);
          })
      }
    },
    getEncuestaDemografia(id) {
      this.axios.get(`/encuestademografias/${id}`)
        .then(res => {
          if (res.data === null) {
            this.encuestademografia = {
              demografiaId: []
            }
          } else {
            this.encuestademografia = res.data;
          }
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    //STEP 2
    getEncuestaDimension(id) {
      this.encuestadimension = [];
      this.preguntaDimension = [];
      this.axios.get(`/encuestadimensiones/${id}`)
        .then(res => {
          this.elementDimension = {
            dimensionId: []
          }
          if (res.data === null || res.data.length == 0) {
            this.encuestadimension = [];
          } else {
            console.log(res.data);
            this.encuestadimension = res.data
            // Función para que se cargue las opciones del CHECK ya CHECKEADOS
            let i
            for (i = 0; i < this.encuestadimension.length; i++) {
              this.preguntaDimension.push(this.encuestadimension[i].dimension)
              this.elementDimension.dimensionId.push(this.encuestadimension[i].dimensionId)
            }
          }
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    deleteEncuestaDimension(id) {
      this.$confirm('¿Deseas eliminar la dimensión?', { title: 'Alerta' })
        .then(res => {
          if (res == true) {
            this.axios.delete(`/encuestadimensiones/${id}`)
              .then(res => {
                const index = this.encuestadimension.findIndex(item => item._id === res.data._id);
                this.encuestadimension.splice(index, 1);
                this.preguntaDimension.splice(index, 1);
                this.snackbar = true
                this.mensaje.texto = '¡Dimensión eliminada!'
              })
              .catch(e => {
                this.snackbar = true
                this.mensaje.texto = '¡La encuesta no puede ser modificada, esta siendo usada en un proceso!';
                console.log(e.response);
              })
          }
        })
    },
    agregarEncuestaDimension(item) {
      console.log(item.dimensionId);
      if (item.dimensionId.length == 0) {
        this.snackbar = true
        this.mensaje.texto = '¡Seleccione alguna dimensión!'
      } else {
        console.log(this.encuestaeditado.id);
        this.axios.delete(`/encuestadimensionesall/${this.encuestaeditado.id}`)//eliminamos todas las dimensiones
          .then(respuesta => {
            console.log(respuesta.data.length);
            this.elementDimension = {
              dimensionId: []
            }

            let i
            for (i = 0; i < item.dimensionId.length; i++) {
              this.axios.get(`/dimension/${item.dimensionId[i]}`)
                .then(res => {
                  this.encuestadimensionAdd.dimensionId = res.data._id
                  this.encuestadimensionAdd.dimension = res.data.nombre
                  this.encuestadimensionAdd.encuestaId = this.encuestaeditado.id
                  //Solo agregamos para cargar en el select de preguntas
                  this.preguntaDimension.push(res.data.nombre)

                  this.axios.post('/nueva-encuestadimensiones', this.encuestadimensionAdd)
                    .then(res => {
                      if (res.data === 'vacio') {
                        console.log('no se modifico');
                      } else {
                        this.encuestadimension.push(res.data)
                        this.elementDimension.dimensionId.push(res.data.dimensionId)
                      }
                      for (let j = 0; j < respuesta.data.length; j++) {
                        this.elementDimension.dimensionId.push(respuesta.data[j])
                      }
                    })
                    .catch(e => {
                      this.snackbar = true
                      this.mensaje.texto = '¡La encuesta no puede ser modificada, esta siendo usada en un proceso!';
                    })
                  this.encuestadimensionAdd = {
                    dimensionId: '',
                    dimension: '',
                    dimensionPuntaje: 0,
                    encuestaId: ''
                  },
                    this.elementDimension = {
                      dimensionId: []
                    }

                  this.snackbar = true
                  this.mensaje.texto = '¡Dimensión agregada!'

                })
                .catch(e => {
                  console.log(e.response);
                })
            }
          })
          .catch(e => {
            console.log(e.response);
            this.snackbar = true
            this.mensaje.texto = '¡La encuesta no puede ser modificada, esta siendo usada en un proceso!';
          })



      }

    },
    editarEncuestaDimension(item) {
      this.axios.put(`/encuestadimensiones/${item._id}`, item)
        .then(res => {
          console.log(res.data);
          const index = this.encuestadimension.findIndex(n => n._id === res.data._id);
          this.encuestadimension[index].dimensionPuntaje = res.data.dimensionPuntaje;
          this.snackbar = true
          this.mensaje.texto = '¡Dimensión calibrada!'
        })
        .catch(e => {
          this.snackbar = true
          this.mensaje.texto = '¡La encuesta no puede ser modificada, esta siendo usada en un proceso!';
          console.log(e.response);
        })
    },
    //STEP 3
    getEncuestaPreguntas(id) {
      this.preguntasCerradas = [];
      this.preguntasAbiertas = [];
      this.axios.get(`/encuestapreguntas/${id}`)
        .then(res => {
          console.log(res.data);
          if (res.data === null || res.data.length == 0) {
            this.preguntasCerradas = [];
            this.preguntasAbiertas = [];
          } else {
            let i
            for (i = 0; i < res.data.length; i++) {
              if (res.data[i].tipoPregunta == 'cerrada') {
                this.preguntasCerradas.push(res.data[i])
              } else {
                this.preguntasAbiertas.push(res.data[i])
              }
            }
          }
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    agregarEncuestaPregunta() {
      if (this.agregarPreguntaLoading === true) {
        return;
      }
      if (this.preguntasAbiertas.length >= 10 && this.preguntaEncuesta.tipoPregunta == 'abierta') {
        this.$props.popup("No se puede agregar más de 10 preguntas abiertas.", "error");
        return;
      } else if (this.preguntasCerradas.length >= 80 & this.preguntaEncuesta.tipoPregunta == 'cerrada') {
        this.$props.popup("No se puede agregar más de 80 preguntas cerradas.", "error");
        return;
      }
      this.agregarPreguntaLoading = true;
      if (this.$refs.formAddPregunta.validate()) {
        const cuerpoEncPreg = this.preguntaEncuesta;
        cuerpoEncPreg.encuestaId = this.encuestaeditado.id
        this.axios.post('/nueva-encuestapreguntas', cuerpoEncPreg)
          .then(res => {
            console.log(res.data);
            if (cuerpoEncPreg.tipoPregunta == 'abierta') {
              this.preguntasAbiertas.push(res.data)
            } else {
              this.preguntasCerradas.push(res.data)
            }
            this.preguntaEncuesta.pregunta = ""
            this.preguntaEncuesta.tipoPregunta = null
            this.preguntaEncuesta.encuestaId = ""
            this.preguntaEncuesta.dimension = ""
            this.snackbar = true
            this.mensaje.texto = '¡Pregunta agregada!'
            this.dialog3 = false
            this.$refs.formAddPregunta.reset()
            this.agregarPreguntaLoading = false;
          })
          .catch(e => {
            this.preguntaEncuesta.pregunta = ""
            this.preguntaEncuesta.tipoPregunta = null
            this.preguntaEncuesta.encuestaId = ""
            this.preguntaEncuesta.dimension = ""
            this.dialog3 = false
            this.snackbar = true
            this.agregarPreguntaLoading = false;
            this.mensaje.texto = '¡La encuesta no puede ser modificada, esta siendo usada en un proceso!';
            console.log(e.response);
          })
      } else {
        this.agregarPreguntaLoading = false;
      }
    },
    deleteEncuestaPregunta(id) {
      this.$confirm('¿Deseas eliminar la pregunta?', { title: 'Alerta' })
        .then(res => {
          if (res == true) {
            this.axios.delete(`/encuestapregunta/${id}`)
              .then(res => {
                if (res.data.tipoPregunta == 'abierta') {
                  const index = this.preguntasAbiertas.findIndex(item => item._id === res.data._id);
                  this.preguntasAbiertas.splice(index, 1);
                } else {
                  const index = this.preguntasCerradas.findIndex(item => item._id === res.data._id);
                  this.preguntasCerradas.splice(index, 1);
                }
                this.snackbar = true
                this.mensaje.texto = '¡Pregunta eliminada!'
              })
              .catch(e => {
                this.snackbar = true
                this.mensaje.texto = '¡La encuesta no puede ser modificada, esta siendo usada en un proceso!';
                console.log(e.response);
              })
          }
        })
    },
    activarEdicionEncuestaPregunta(id) {
      this.editarPregunta = true;
      this.axios.get(`/encuestapregunta/${id}`)
        .then(res => {
          console.log(res.data);
          this.dialog3 = true
          this.encuestaPreguntaEditar = res.data;
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    editarEncuestaPregunta(item) {
      if(item.tipoPregunta == 'abierta' && this.preguntasAbiertas.length >= 10){
        this.$props.popup("No se puede agregar más de 10 preguntas abiertas.", "error");
        return;
      } else if(item.tipoPregunta == 'cerrada' && this.preguntasCerradas.length >= 50){
        this.$props.popup("No se puede agregar más de 50 preguntas cerradas.", "error");
        return;
      }
      if (this.$refs.formEditPregunta.validate()) {
        this.axios.put(`/encuestapregunta/${item._id}`, item)
          .then(res => {
            //Si restipoPreguntaBeforeChange es igual, no cambiamos tipo de pregunta
            if (res.data.restipoPreguntaBeforeChange === "igual") {
              if (res.data.encuestapreguntasDB.tipoPregunta === "abierta") {
                const index = this.preguntasAbiertas.findIndex(n => n._id === res.data.encuestapreguntasDB._id);
                this.preguntasAbiertas[index].pregunta = res.data.encuestapreguntasDB.pregunta;
                this.preguntasAbiertas[index].dimension = res.data.encuestapreguntasDB.dimension;
              } else {
                const index = this.preguntasCerradas.findIndex(n => n._id === res.data.encuestapreguntasDB._id);
                this.preguntasCerradas[index].pregunta = res.data.encuestapreguntasDB.pregunta;
                this.preguntasCerradas[index].dimension = res.data.encuestapreguntasDB.dimension;
              }
            } else {
              if (res.data.encuestapreguntasDB.tipoPregunta === "abierta") {//si es abierta, entonces eliminamos de cerrada
                const index = this.preguntasCerradas.findIndex(n => n._id === res.data.encuestapreguntasDB._id);
                this.preguntasCerradas.splice(index, 1);
                //agregamos en abiertas
                this.preguntasAbiertas.push(res.data.encuestapreguntasDB)
              } else {
                //Si es cerrada, entonces eliminamos de abiertas
                const index = this.preguntasAbiertas.findIndex(n => n._id === res.data.encuestapreguntasDB._id);
                this.preguntasAbiertas.splice(index, 1);
                //Agregamos en cerradas
                this.preguntasCerradas.push(res.data.encuestapreguntasDB)
              }
            }
            this.snackbar = true
            this.mensaje.texto = '¡Pregunta editada!';
            this.editarPregunta = false;
            this.dialog3 = false
            this.$refs.formEditPregunta.reset()
          })
          .catch(e => {
            this.snackbar = true
            this.dialog3 = false
            this.mensaje.texto = '¡La encuesta no puede ser modificada, esta siendo usada en un proceso!';
            console.log(e.response);
          })
      }
    },
    opcionesEncuestaPregunta(id) {
      this.axios.get(`/encuesta/${id}`)
        .then(res => {
          this.encuestaPreguntaOpciones = res.data;
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    resetPreguntaAdd() {
      this.$refs.formAddPregunta.reset()
    },
    resetPreguntaEdit() {
      this.$refs.formEditPregunta.reset()
    },
    //VALIDATE STATUS
    validateStatusEncuesta(id) {
      this.dialog4 = true;
      this.axios.put(`/encuestaValidate/${id}`)
        .then(res => {
          const index = this.encuestas.findIndex(n => n._id === res.data._id);
          this.encuestas[index].estado = res.data.estado;
          this.listarEncuestas();
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    selectDatoDemografico(itemId) {
      if(this.encuestademografia.demografiaId.length > 15) {
        this.encuestademografia.demografiaId.pop();
        this.$props.popup('¡Solo se pueden agregar 15 datos demográficos!', 'error');
        return;
      }
    },
    selectDimension(itemId) {
      if(this.elementDimension.dimensionId.length > 15) {
        this.elementDimension.dimensionId.pop();
        this.$props.popup('¡Solo se pueden agregar 15 dimensiones!', 'error');
        return;
      }
    }
  },
  props: {
    popup: Function
  }
}
</script>

<style scoped>
/* .v-tooltip__content {
    opacity: 1.0 !important;
    background: #ffffff;
    color: #9e9e9e;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  } */
</style>
