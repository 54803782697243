<template>
  <div class="video-js-responsive-container vjs-hd">
    <video ref="videoPlayer" class="video-js vjs-matrix"></video>
  </div>
</template>
 
<style scoped>
.video-js-responsive-container.vjs-hd {
  padding-top: 56.25%;
}
.video-js-responsive-container.vjs-sd {
  padding-top: 75%;
}
.video-js-responsive-container {
  width: 100%;
  position: relative;
}
.video-js-responsive-container .video-js {
  height: 100% !important;
  width: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.vjs-matrix.video-js {
  background-color: #FFFFFF;
}
</style>

  <script>
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    videoSrc:String,
  },
  data() {
    return {
      player: null,
    };
  },
  watch: {
    videoSrc(value) {
        videojs.getPlayer(this.$refs.videoPlayer).src(value);
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      this.player.log("onPlayerReady", this);
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>