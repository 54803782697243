<template>
    <v-expansion-panels class="expansion-panel--yellow" v-if="hasRespuestasAbiertas">
        <v-expansion-panel>
            <v-expansion-panel-header class="custom-header--yellow">
                Extraer Respuestas Abiertas
            </v-expansion-panel-header>
            <v-expansion-panel-content class="custom-content--yellow">
                <v-card-actions class="justify-center pa-0 mb-2">
                </v-card-actions>
                <div class="button-space--yellow">
                    <v-btn color="orange" class="text-capitalize" :loading="loading2" :disabled="loading2" bottom
                        @click="reportGeneral(); loading2 = true" block>
                        Extraer Respuestas Abiertas
                        <template v-slot:loader>
                            <span>Generando...</span>
                        </template>
                    </v-btn>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    name: 'reporte-pdf',
    data() {
        return {
            procesoModal: {},
            report: {
                checks: [],
                filter: [],
            },
            selectedCheckReport: [],
            select2: [],
            loader: null,
            loading: false,
            loading2: false,
            hasRespuestasAbiertas: false,
        }
    },
    props: {
        procesoId: {
            type: String, // or String, Boolean, Object, Array, etc. depending on your needs
            required: true, // or false if it's not required
        },
    },
  watch: {
    procesoId(newProceso, oldProceso) {
      this.checkRespuestasAbiertas();
    }
  },
    mounted() {
      this.checkRespuestasAbiertas();
    },
    methods: {
        checkRespuestasAbiertas() {
        this.axios
        .get(`${process.env.VUE_APP_BACKEND_NEW_URL}/reportes/hasRespuestasAbiertas/${this.procesoId}`)
        .then(response => {
            this.hasRespuestasAbiertas = response.data["hasRespuestasAbiertas"];
        })
        .catch(e => {
            console.log(e.response);
        });

        },
        activarEdicionIntroductionReport(id) {
            this.$emit('activarEdicionIntroductionReport', this.procesoId);
        },
        reportGeneral() {
            this.loader = false;
            this.report.checks = this.selectedCheckReport;
            this.report.filter = this.select2;
            this.axios.post(`${process.env.VUE_APP_BACKEND_NEW_URL}/reportes/extraerRespuestasAbiertas/${this.procesoId}`, this.report, {
                responseType: "blob"
            })
                .then(res => {
                    var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'reporte-surveys.xlsx');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.loading = false;
                    this.loading2 = false;
                    this.loader = null;

                })
                .catch(e => {
                    this.loading = false;
                    this.loading2 = false;
                    this.loader = null;
                    console.log(e.response);
                })
        },
    }
}
</script>

<style>
.expansion-panel--yellow {
    margin-bottom: 10px;
}

.custom-header--yellow {
    border: 1px solid #F9A825;
    color: #F9A825;
    font-weight: 400;
    text-transform: uppercase;
}

.custom-header--yellow:hover {
    background-color: #F9A825;
    color: #fff;
    transition: all 0.3s ease-in-out;
}

.custom-content--yellow {
    border: 1px solid #F9A825;
    padding: 10px 5px;
    color: #F9A825;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.button-space--yellow {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
