<template>
    <dialog class="dialog" open v-if="show == true">
        <slot></slot>
    </dialog>
</template>
  
<script>
export default {
    props: [
        'show'
    ]
}
</script>
  
<style scoped>
.dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
  