<template>
  <div class="picker mb-2">
    <p class="label">{{ label }}</p>
    <div class="datetimepicker">
      <input type="date" v-model="localDate" ref="date" />
      <span></span>
      <input type="time" v-model="localTime" ref="time" />
    </div>
  </div>
</template>
  
<script>
export default {
  props: ['value', 'label'],
  data() {
    return {
      localDate: '',
      localTime: '',
      isDateSupported: false,
      isTimeSupported: false
    };
  },
  computed: {
    combinedDatetime: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
  watch: {
    localDate: "combineDateTime",
    localTime: "combineDateTime"
  },
  methods: {
    combineDateTime() {
      if (this.localDate && this.localTime) {
        const localDateTime = new Date(`${this.localDate}T${this.localTime}`);

        // Format the UTC date and time
        const utcDate = localDateTime.toISOString().split('T')[0];
        const utcTime = localDateTime.toISOString().split('T')[1].split('.')[0];

        // Emit the combined UTC datetime
        this.combinedDatetime = `${utcDate}T${utcTime}Z`;
      }
    }
  },
  mounted() {
    if (this.value) {
      // Create a Date object using the received UTC datetime
      const utcDateTime = new Date(this.value);

      // Convert to local time
      const localDateTime = new Date(utcDateTime.getTime() - utcDateTime.getTimezoneOffset() * 60000);

      // Format the local date and time
      const localDate = localDateTime.toISOString().split('T')[0];
      const localTime = localDateTime.toISOString().split('T')[1].split('.')[0].slice(0, 5); // HH:MM

      this.localDate = localDate;
      this.localTime = localTime;
    }
    this.isDateSupported = this.$refs.date.type === 'date';
    this.isTimeSupported = this.$refs.time.type === 'time';
  }
};
</script>
  
<style scoped>
* {
  box-sizing: border-box;
}

.picker {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding: 0;
  margin: 0;
  margin-bottom: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.label {
  font-size: 12px;
  font-weight: 300;
  color: #333;
  margin: 0;
  padding: 0;
}

.datetimepicker {
  display: inline-flex;
  gap: 6px;
  align-items: center;
  border-bottom: 1px solid #333;
  padding: 0 0.5rem;

  &:focus-within {
    border-color: rgb(232, 142, 49);
  }

  input {
    font: inherit;
    color: wehite;
    appearance: none;
    outline: none;
    border: 0;
    background-color: transparent;

    &[type=date] {
      width: 10rem;
      padding: .25rem 0 .25rem .5rem;
      border-right-width: 0;
    }

    &[type=time] {
      width: 5.5rem;
      padding: .25rem .5rem .25rem 0;
      border-left-width: 0;
    }
  }

  span {
    height: 1rem;
    margin-right: .25rem;
    margin-left: .25rem;
    border-right: 1px solid #ddd;
  }
}
</style>
  