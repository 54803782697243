<template>
  <v-container grid-list-xl>
    <v-row justify="center">
      <v-col cols="12" md="8" sm="12">
        <v-row>
          <div class="align-center justify-center mx-auto">
            <h1 class="grey--text text--darken-2">¡Bienvenido nuevamente, <span class="green--text text--darken-3">{{
              nameUser }}</span>!</h1>
          </div>
        </v-row>
        <v-row>
          <div class="align-center justify-center mx-auto">
            <p class="grey--text text--darken-1">Evalúa la calidad del ambiente que se percibe en tu empresa a través de
              multitest surveys</p>
          </div>
        </v-row>
        <v-row>
          <div class="align-center justify-center mx-auto">
            <v-btn color="#E88E31" outlined router to="/proceso">
              Crear nuevo proceso
            </v-btn>
          </div>
        </v-row>
        <v-row>
          <div class="align-center justify-center pa-6 mx-auto">
            <h4 class="grey--text text--darken-2">Pasos para crear un nuevo proceso con nueva información</h4>
          </div>
        </v-row>
        <v-row>
          <div class="mx-auto">
            <v-tooltip top max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-card class="mx-auto" color="#D9BA27" dark v-bind="attrs" v-on="on" router to="/demografia">
                  <div class="align-center justify-center mx-auto px-5 py-1">
                    <div class="align-center justify-center mx-auto">
                      <v-icon medium left>fas fa-chart-bar</v-icon>
                      <span class="title font-weight-bold">Paso 1</span>
                    </div>
                  </div>
                </v-card>
                <span class="grey--text text-body-2">
                  CREAR DEMOGRAFÍA
                </span>
              </template>
              <span>La demografía consiste en dividir la empresa en grupos más pequeños, este tipo de segmentación toma en
                cuenta variables como antigüedad, ingresos, grado de estudios, área, etc.</span>
            </v-tooltip>
          </div>
          <div class="mx-auto">
            <v-tooltip top max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-card class="mx-auto" color="#D91C60" dark v-bind="attrs" v-on="on" router to="/dimension">
                  <div class="align-center justify-center mx-auto px-5 py-1">
                    <div class="align-center justify-center mx-auto">
                      <v-icon medium left>fas fa-chart-line</v-icon>
                      <span class="title font-weight-bold">Paso 2</span>
                    </div>
                  </div>
                </v-card>
                <span class="grey--text text-body-2 ml-1">
                  CREAR DIMENSIÓN
                </span>
              </template>
              <span>La dimensión son factores específicos que influyen en el comportamiento de las personas, y que pueden
                ser cuantificados, estas pueden ser liderazgo, motivación, optimismo, etc.</span>
            </v-tooltip>
          </div>
          <div class="mx-auto">
            <v-tooltip top max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-card class="mx-auto" color="#27D9D2" dark v-bind="attrs" v-on="on" router to="/encuesta">
                  <div class="align-center justify-center mx-auto px-5 py-1">
                    <div class="align-center justify-center mx-auto">
                      <v-icon medium left>fas fa-chart-pie</v-icon>
                      <span class="title font-weight-bold">Paso 3</span>
                    </div>
                  </div>
                </v-card>
                <span class="grey--text text-body-2 ml-2">
                  CREAR ENCUESTA
                </span>
              </template>
              <span>La encuesta hace uso de la demografía, dimensión y preguntas que serán creadas por el encuestador y
                finalmente puedan ser aplicados a los colaboradores de su empresa.</span>
            </v-tooltip>
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" md="4" sm="6" class="mt-2">
        <v-row>
          <Actualizaciones></Actualizaciones>
        </v-row>
        <v-img src="robot.png" width="150px" class="mx-auto"></v-img>
        <v-row>
          <div class="ml-4 mt-5">
            <p class="grey--text caption">Deseas saber más de multitest surveys</p>
          </div>
        </v-row>
        <v-row>
          <v-btn color="#298A45" text @click="show = !show, menu = false">
            Leer más...
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn icon @click="show = !show">
            <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </v-row>
        <v-expand-transition>
          <div v-show="show">
            <v-divider></v-divider>
            <v-card-text class="justify-center">
              <p class="grey--text body-2 text-justify">MULTITEST SURVEYS es un sistema en línea donde sus colaboradores
                podrán contestar su encuesta en línea y usted supervisar el progreso en tiempo real; al finalizar podrá
                generar de manera automatizada los reportes con promedios generales y niveles de satisfacción de acuerdo a
                las percepciones de sus colaboradores para generar planes de acción que impacten de manera positiva en su
                empresa.</p>
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-col>
      <v-col cols="12" md="8" sm="6">
        <v-row>
          <div class="align-center justify-center mt-5 mx-auto">
            <h4 class="grey--text text--darken-2">Cantidad de personas encuestadas por año</h4>
          </div>
        </v-row>
        <div class="pl-5 pr-5 mt-5">
          <v-card class="mx-auto text-center" color="white" dark>
            <v-sheet color="white">
              <v-sparkline :labels="labels" :value="value" color="#298A45" stroke-linecap="round" smooth height="80"
                padding="24" line-width="2" label-size="6">
                <template v-slot:label="item">
                  {{ item.value }}
                </template>
              </v-sparkline>
            </v-sheet>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-row>
          <div class="align-center justify-center mx-auto">
            <h4 class="grey--text text--darken-2">Últimos procesos creados</h4>
          </div>
        </v-row>
        <div v-if="Object.keys(procesosUser).length != 0">
          <v-list v-for="item in procesosUser" :key="item.id" height=70>
            <v-list-item>
              <v-list-item-avatar color="#298A45">
                <v-icon dark>fas fa-book</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-tooltip right max-width="200">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-subtitle v-bind="attrs" v-on="on">{{ item.name }}</v-list-item-subtitle>
                  </template>
                  <span>{{ item.name }}</span>
                </v-tooltip>
              </v-list-item-content>
              <v-list-item-content align="right">
                <v-list-item-subtitle>{{ item.numberEncuestados }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ item.date }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset></v-divider>
          </v-list>
        </div>
        <div v-else>
          <p class="grey--text body1 mt-3">No hemos encontrado procesos creados en el sistema</p>
          <v-card class="mx-auto " max-width="400">
            <v-card-text>
              <v-row align="center">
                <v-col class="display-3" cols="12">
                  <p class="purple--text text--lighten-2">0 Procesos</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import Actualizaciones from '../components/features/home/Actualizaciones.vue'
import store from '../store'

export default {
  data() {
    return {
      show: false,
      labels: [],
      value: [],
      nameUser: '',
      procesosUser: [],
      menu: false,
    }
  },
  name: 'Home',
  components: {
    Actualizaciones
  },
  computed: {
    ...mapState(['token']),
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  created() {
    this.getInformationUser();
    this.getInformationProcess();
    this.executeDataByDefault();
    this.cantidadEmpleadosEncuestados();
  },
  methods: {
    getInformationUser() {
      this.axios.get('/user')
        .then(res => {
          this.nameUser = res.data.nombre
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    getInformationProcess() {
      this.axios.get('/procesohome')
        .then(res => {
          this.procesosUser = res.data
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    cantidadEmpleadosEncuestados() {
      this.axios.get('/cantidadEmpleadosEncuestados')
        .then(res => {
          for (let i = 0; i < res.data.arrayProcesosByYear.length; i++) {
            this.value.push(res.data.arrayProcesosByYear[i]['cantidad']);
            this.labels.push(`Año ${res.data.arrayProcesosByYear[i]['year']} - ${res.data.arrayProcesosByYear[i]['cantidad']}`);
          }
        })
        .catch(e => {
          console.log(e.response);
        })
    },
    executeDataByDefault() {
      this.axios.get('/checkUserDataByDefault')
        .then(res => {
          if (res.data != 0) {
            this.axios.get('/insertDataByDefault')
              .then(res => {
                console.log('ejecutando');
              })
              .catch(e => {
                console.log(e.response);
              })
          }
        })
        .catch(e => {
          console.log(e.response);
        })
    },
  },
}
</script>

<style scoped>
.rounded {
  border-radius: 30px;
}

.ligne {
  border-right: solid 1px #95a5a6;
}

.v-tooltip__content {
  opacity: 1.0 !important;
  background: #ffffff;
  color: #9e9e9e;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
</style>
