<template>
    <div class="align-center justify-center mx-auto">
        <v-menu v-model="menu" bottom right transition="scale-transition" origin="top left">
            <template v-slot:activator="{ on }">
                <v-chip pill v-on="on" color="white">
                    <v-badge :content="updates.length" color="red" overlap>
                        <h3 class="grey--text text--darken-2">Nueva actualización<span
                                class="white--text text--lighten-2">....</span></h3>
                    </v-badge>
                </v-chip>
            </template>
            <v-card width="300">
                <v-list white>
                    <v-divider class="mx-4"></v-divider>
                    <v-list-item v-for="(update, index) in updates" :key="index">
                        <v-list-item-content>
                            <v-list-item-title>{{ update.title }}</v-list-item-title>
                            <v-list-item-subtitle>
                                <div class="text-break">
                                    {{ update.content }}<br><br>
                                    <!-- {{ timeSince(update.date) }} -->
                                </div>
                            </v-list-item-subtitle>
                            <v-divider class="mx-4 mt-4"></v-divider>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
export default {
    data() {
        return {
            menu: false,
            updates: [
                { title: "Inteligencia Artificial", content: "Hemos implementado un mejor modelo de Inteligencia Artificial en nuestro reporte PDF" },
            ]
        };
    },
    // methods: {
    //     timeSince(date) {
    //         const now = new Date();
    //         const difference = Math.abs(now - date);
    //         return "Hace " + Math.round(Math.floor(difference / (1000 * 60 * 60 * 24))) + " dias";
    //     }
    // }
}
</script>

<style>
.text-break {
    word-break: break-word;
    white-space: normal;
}
</style>
