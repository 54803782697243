<template>
    <v-simple-table fixed-header height="340px">
        <template v-slot:default>
            <thead>
                <tr>
                    <th>
                        <v-checkbox color="#298A45" :value="seleccionados.length === encuestadosFiltered.length"
                            @click="clickSelectAll()"></v-checkbox>
                    </th>
                    <th class="text-left">NOMBRES</th>
                    <th class="text-left">APELLIDOS</th>
                    <th class="text-left">GÉNERO</th>
                    <th class="text-left">CORREO</th>
                    <th class="text-left">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="estado-envio-button" v-bind="attrs" v-on="on">{{ estadoTableLabel }}
                                    <v-icon>mdi-chevron-down</v-icon>
                                </div>
                            </template>
                            <v-list>
                                <v-list-item link v-for="estado in estados" :key="estado"
                                    @click="filtrarPorEstado(estado)">
                                    <v-list-item-title>{{ estado }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </th>
                    <th class="text-left">ACCIONES</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in procesoEncuestados" :key="index"
                    v-if="estadoSeleccionado === undefined || item.estadoEmail === estadoSeleccionado">
                    <td width="5%">
                        <v-checkbox color="#298A45" :value="isSelected(item)" @click="seleccionarProceso(item)">
                        </v-checkbox>
                    </td>
                    <td>{{ item.nombre }}</td>
                    <td>{{ item.apellido }}</td>
                    <td>{{ item.genero }}</td>
                    <td>{{ item.correo }}</td>
                    <td>{{ item.estadoEmail }}</td>
                    <td width="10%">
                        <v-card-actions class="justify-space-between">
                            <v-btn color="success" fab x-small dark @click="activarEdicionProcesoEncuestado(item._id)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn color="error" fab x-small dark @click="deleteEncuestado(item._id)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<style>
.estado-envio-button {
    cursor: pointer;
}
</style>

<script>
import { map } from 'rxjs';
export default {
    data() {
        return {
            procesoEncuestados: [],
            seleccionados: [],
            estados: ['Todos', 'Ingresado', 'Enviado'],
            estadoSeleccionado: undefined
        }
    },
    watch: {
        seleccionados(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.encuestadosSeleccionados.next(newValue);
            }
        }
    },
    computed: {
        estadoTableLabel() {
            if (this.estadoSeleccionado === undefined) {
                return 'ESTADO ENVIO';
            }
            return this.estadoSeleccionado.toUpperCase();
        },
        encuestadosFiltered() {
            return this.procesoEncuestados.filter(enc => {
                if (this.estadoSeleccionado === undefined) {
                    return true;
                }

                return enc.estadoEmail === this.estadoSeleccionado;
            });
        }
    },
    methods: {
        listarProcesoEncuestados(id) {
            this.procesoEncuestados = [];
            this.axios.get(`/procesoencuestados/${id}`)
                .then(res => {
                    if (res.data === null || res.data.length == 0) {
                        this.procesoEncuestados = [];
                    } else {
                        this.procesoEncuestados = res.data;
                    }
                })
                .catch(e => {
                    console.log(e.response);
                })
        },
        clickSelectAll() {
            /*
                allEncuestados es la variable que se utilizara para determinar que encuestados
                seleccionar. Esto debido a que tenemos un filtro por estado. 
             */
            const allEncuestados = this.encuestadosFiltered;

            if (this.seleccionados.length === allEncuestados.length) {
                this.seleccionados = [];
                return;
            }
            this.seleccionados = allEncuestados;
        },
        seleccionarProceso(item) {
            if (this.seleccionados.includes(item) === true) {
                this.seleccionados = this.seleccionados.filter(value => value._id !== item._id);
                this.encuestadosSeleccionados.next(this.seleccionados);
                return;
            } else {
                this.seleccionados = [...this.seleccionados, item];
                return;
            }
        },
        isSelected(item) {
            const isFound = this.seleccionados.find(value => item._id === value._id);
            return isFound !== undefined;
        },
        filtrarPorEstado(estado) {
            if (estado === "Todos") {
                this.estadoSeleccionado = undefined;
            } else {
                this.estadoSeleccionado = estado;
            }
            this.seleccionados = [];
            this.encuestadosSeleccionados.next(this.seleccionados);
        },
        activarEdicionProcesoEncuestado(id) {
            this.$emit("editarEncuestado", id);
        },
        deleteEncuestado(id) {
            this.$emit("deleteEncuestado", id);
        }
    },
    mounted() {
        this.encuestados.subscribe((data) => {
            console.log(data);
            this.procesoEncuestados = data;
        });

        this.encuestadosSeleccionados.subscribe((data) => {
            console.log(data);
            this.seleccionados = data;
        })

        this.clearProcesoTable.subscribe(() => {
            this.estadoSeleccionado = undefined;
        });
    },
    props: [
        'encuestados',
        'selectAll',
        'procesoEncuestadosEnvio',
        'encuestadosSeleccionados',
        'clearProcesoTable'
    ]
}
</script>
