<template>
    <v-expansion-panels class="expansion-panel">
        <v-expansion-panel>
            <v-expansion-panel-header class="custom-header">
                Reporte PDF
            </v-expansion-panel-header>
            <v-expansion-panel-content class="custom-content">
                <span v-if="hasRespuestasAbiertas">
                    <v-checkbox v-model="selectedAIReport" label="Incluir sección de IA" color="#298A45"
                        hide-details></v-checkbox>
                    <p>El nuevo reporte de Surveys que utiliza la IA!</p>
                </span>
                <v-card-actions class="justify-center pa-0 mb-2">
                    <span class="text-caption grey--text">Editar la introducción del reporte
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon x-small color="#298A45"
                                    @click="activarEdicionIntroductionReport(procesoModal._id)" v-bind="attrs" v-on="on">
                                    <v-icon>mdi-pen-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Click aquí para editar</span>
                        </v-tooltip>
                    </span>
                </v-card-actions>
                <div class="button-space">
                    <v-btn color="success" class="text-capitalize" :loading="loading2" :disabled="loading2" bottom
                        @click="reportGeneral(); loading2 = true" block>
                        Obtener Reporte
                        <template v-slot:loader>
                            <span>Generando...</span>
                        </template>
                    </v-btn>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    name: 'reporte-pdf',
    data() {
        return {
            procesoModal: {},
            report: {
                checks: [],
                filter: [],
                includeAi: false,
            },
            selectedCheckReport: [],
            select2: [],
            loader: null,
            loading: false,
            loading2: false,
            selectedAIReport: false,
            hasRespuestasAbiertas: false,
        }
    },
    props: {
        procesoId: {
            type: String, // or String, Boolean, Object, Array, etc. depending on your needs
            required: true, // or false if it's not required
        },
    },
    watch: {
        procesoId(newProceso, oldProceso) {
            this.checkRespuestasAbiertas();
        }
    },
    mounted() {
        this.checkRespuestasAbiertas();
    },
    methods: {
        activarEdicionIntroductionReport(id) {
            this.$emit('activarEdicionIntroductionReport', this.procesoId);
        },
        reportGeneral() {
            this.loader = false;
            this.report.checks = this.selectedCheckReport;
            this.report.filter = this.select2;
            this.report.includeAi = this.selectedAIReport;
            this.axios.post(`${process.env.VUE_APP_BACKEND_NEW_URL}/reportes/reporteGeneral/${this.procesoId}`, this.report, {
                responseType: "blob"
            })
                .then(res => {
                    var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'reporte-surveys.pdf');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.loading = false;
                    this.loading2 = false;
                    this.loader = null;

                })
                .catch(e => {
                    this.loading = false;
                    this.loading2 = false;
                    this.loader = null;
                    console.log(e.response);
                })
        },
        checkRespuestasAbiertas() {
            this.axios
            .get(`${process.env.VUE_APP_BACKEND_NEW_URL}/reportes/hasRespuestasAbiertas/${this.procesoId}`)
            .then(response => {
                this.hasRespuestasAbiertas = response.data["hasRespuestasAbiertas"];
            })
            .catch(e => {
                console.log(e.response);
            });

        },
    }
}
</script>

<style>
.expansion-panel {
    margin-bottom: 10px;
}

.custom-header {
    border: 1px solid #4caf50;
    color: #4caf50;
    font-weight: 400;
    text-transform: uppercase;
}

.custom-header:hover {
    background-color: #4caf50;
    color: #fff;
    transition: all 0.3s ease-in-out;
}

.custom-content {
    border: 1px solid #4caf50;
    padding: 10px 5px;
    color: #9e9e9e;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.button-space {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
