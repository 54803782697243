<template>
  <v-container grid-list-xl>
    <v-row justify="center">
      <v-col cols="12" md="4" sm="6">
        <v-card class="mb-3 pa-3">
          <v-form @submit.prevent="editarDemografia(demografiaEditar)" v-if="editar" ref="formEditD">
            <v-chip class="ml-0 mt-3" color="#D9BA27" label text-color="white">
              <v-list-item-action>
                <v-icon>fas fa-chart-bar</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <h3>MODIFICAR DEMOGRAFÍA</h3>
              </v-list-item-content>
            </v-chip>
            <v-text-field :rules="inputRulesNameDemografia" label="Nombre de demografía" v-model="demografiaEditar.nombre"></v-text-field>
            <v-textarea :rules="inputRulesDescripcionDemografia" label="Descripción de demografía" v-model="demografiaEditar.descripcion"></v-textarea>
            <v-row align="center" justify="space-around" class="mt-3">
              <v-btn rounded color="warning" type="submit" class="ml-10">Modificar</v-btn>
              <v-btn rounded type="submit" class="mr-10"  @click="editar = false; resetDemografia()">Cancelar</v-btn>
            </v-row>
          </v-form>

          <v-form @submit.prevent="agregarDemografia()" v-if="!editar" ref="formAddD">
            <v-chip class="ml-0 mt-3" color="#D9BA27" label text-color="white">
              <v-list-item-action>
                <v-icon>fas fa-chart-bar</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <h3>NUEVA DEMOGRAFÍA</h3>
              </v-list-item-content>
            </v-chip>
            <v-text-field :rules="inputRulesNameDemografia" label="Nombre de demografía" v-model="demografia.nombre" @input="validateNombreDemografia"></v-text-field>
            <v-textarea :rules="inputRulesDescripcionDemografia" label="Descripción de demografía" v-model="demografia.descripcion"></v-textarea>
            <v-btn rounded block color="success" type="submit" class="mt-3">Agregar</v-btn>
          </v-form>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="8" >
        <v-card>
          <v-card-title>
            <v-chip color="#D9BA27" label text-color="white">
              <v-icon left>widgets</v-icon>
              <h3>LISTA DEMOGRAFÍAS</h3>
            </v-chip>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar demografía"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table fixed-header height="350px" :headers="headers" :items="demografias" :search="search" sort-by="id" class="elevation-1" :items-per-page="5">
            <template v-slot:item.accion="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :disabled="item.disabled ? true : false" class="mr-2" fab x-small color="success" @click="activarEdicion(item._id)" v-bind="attrs" v-on="on">
                      <v-icon dark>mdi-pen</v-icon>
                  </v-btn>
                </template>
                <span>Editar demografía</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :disabled="item.disabled ? true : false" class="mr-2" fab x-small color="error" @click="eliminarDemografia(item._id)" v-bind="attrs" v-on="on">
                    <v-icon dark>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar demografía</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-2" fab dark x-small color="warning" @click="dialog = true; modalDemografia(item)"  v-bind="attrs" v-on="on">
                <v-icon dark>mdi-view-list</v-icon>
              </v-btn>
                </template>
                <span>Gestionar atributos</span>
              </v-tooltip>

            </template>
            <template slot="no-data">
              No se encontró ningún registro
            </template>
          </v-data-table>
        </v-card>
      </v-col>

    </v-row>


    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      persistent
    >
      <v-card tile>
        <v-toolbar
          flat
          dark
          color="#298A45"
        >
          <v-btn
            icon
            dark
            @click="dialog = false; editarAtributo = false; validateStatusDemografia(editado.id)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Atributos demografía - {{editado.nombre}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false; editarAtributo = false; validateStatusDemografia(editado.id)"
            >
              Cerrar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-xl>
            <v-row justify="center">
              <v-col cols="12" md="4" sm="6">
                <v-card class="mb-3 pa-5">
                  <v-form @submit.prevent="editarAtributoDemografia(atributodemografiaEditar)" v-if="editarAtributo" ref="formEditAD">
                    <v-chip class="ml-0 mb-2" color="blue lighten-2" label text-color="white">
                      <v-icon left>mdi-label</v-icon>
                      <h2>{{editado.nombre}}</h2>
                    </v-chip>
                    <v-text-field :rules="inputRulesNameAtributoDemo" label="Nombre de atributo demográfico" v-model="atributodemografiaEditar.nombre"></v-text-field>
                    <v-row align="center" justify="space-around" class="mt-3">
                      <v-btn rounded color="warning" type="submit" class="ml-10">Modificar</v-btn>
                      <v-btn rounded type="submit" class="mr-10"  @click="editarAtributo = false;resetAtributoDemografia()">Cancelar</v-btn>
                    </v-row>
                  </v-form>
                  <v-form @submit.prevent="agregarAtributoDemografia(editado.id)" v-if="!editarAtributo" ref="formAddAD">
                    <v-chip class="ml-0 mb-2" color="blue lighten-2" label text-color="white">
                      <v-icon left>mdi-label</v-icon>
                      <h2>{{editado.nombre}}</h2>
                    </v-chip>
                    <v-text-field :rules="inputRulesNameAtributoDemo" label="Nombre de atributo demográfico" v-model="demografiaatributo.nombre"></v-text-field>
                    <v-row align="center" justify="space-around" class="mt-3">
                      <v-btn rounded block color="success" type="submit">Agregar</v-btn>
                    </v-row>
                  </v-form>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="8" >
                <v-card>
                  <v-card-title >
                    <v-chip color="blue lighten-2" label text-color="white">
                      <v-icon left>widgets</v-icon>
                      <h3>LISTA ATRIBUTOS DEMOGRÁFICOS</h3>
                    </v-chip>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Buscar atributo demográfico"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table :headers="headers2" :items="atributosDemografia" :search="search" sort-by="id" class="elevation-1" :items-per-page="5" height="350px">
                    <template v-slot:item.accion="{item}">
                      <v-btn :disabled="item.disabled ? true : false" class="mr-2" fab x-small color="success" @click="activarEdicionAtributoId(item._id)">
                        <v-icon dark>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn :disabled="item.disabled ? true : false" class="mr-2" fab x-small color="error" @click="eliminarAtributoDemografia(item._id)">
                        <v-icon dark>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <template slot="no-data">
                      No se encontró ningún registro
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar">
      {{ mensaje.texto }}
      <template>
        <v-btn
        color="blue"
        text
        @click="snackbar = false"
        class="ml-16">
            Cerrar
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>

  import { argv0 } from 'process';
import {mapState} from 'vuex'

  export default {
    data() {
      return {
        demografias: [],
        mensaje: {color: '', texto: ''},
        demografia: {
            nombre: '',
            descripcion: ''
        },
        editar: false,
        demografiaEditar: {},
        search: '', //para el cuadro de búsqueda de datatables
        snackbar: false, //para el mensaje del snackbar
        headers: [
          // { text: 'ID', align: 'left', sortable: false,value: '_id'},
          { text: 'DEMOGRAFÍA', value:'nombre',width: '20%'},
          { text: 'DESCRIPCIÓN', value: 'descripcion',width: '40%'},
          { text: 'ESTADO', value: 'estado'},
          { text: 'ACCIONES', value: 'accion', sortable: false },
        ],
        dialog: false,
        inputRulesNameDemografia: [
          v => !!v || 'Nombre de demografía es requerido'
        ],
        inputRulesDescripcionDemografia: [
          v => !!v || 'Descripción de demografía es requerido',
          v => (v && v.length >= 5) || 'Descripción de demografía debe de ser mayor a 5 caracteres'
        ],
        //DATOS PARA ATRIBUTOS DE DEMOGRAFIA
        headers2: [
          // { text: 'ID', align: 'left', sortable: false,value: '_id'},
          { text: 'NOMBRE ATRIBUTO DEMOGRÁFICO', value:'nombre'},
          { text: 'ACCIONES', value: 'accion', sortable: false },
        ],
        inputRulesNameAtributoDemo: [
          v => !!v || 'Nombre de atributo demográfico es requerido',
          v => (v && v.length <= 37) || 'El atributo demográfico debe de ser menos de 37 caracteres'
        ],
        editado: {
          id: '',
          nombre: '',
          descripcion: ''
        },
        atributosDemografia: [],
        demografiaatributo: {
            nombre: '',
            demografiaId: ''
        },
        editarAtributo: false,
        atributodemografiaEditar: {}
      }
    },
    computed: {
      ...mapState(['token'])
    },
    created() {
        this.listarDemografias();
    },
    methods: {
      agregarDemografia(){
        if(this.$refs.formAddD.validate()){
          this.axios.post('/nueva-demografia', this.demografia)
            .then(res=> {
              console.log(res.data);
              this.demografias.push(res.data)
              this.demografia.nombre = ''
              this.demografia.descripcion = ''
              this.snackbar = true
              this.mensaje.texto = '¡Demografía agregada!'
              this.listarDemografias();
              this.$refs.formAddD.reset()
            })
            .catch(e=>{
              console.log(e.response);
              if(e.response.data.error.errors.nombre.message){
                this.snackbar = true
                this.mensaje.texto = e.response.data.error.errors.nombre.message
              }else{
                this.snackbar = true
                this.mensaje.texto = 'Error de sistema'
              }
            })
        }
      },
      activarEdicion(id){
        this.editar = true;
        this.axios.get(`/demografia/${id}`)
          .then(res => {
              this.demografiaEditar = res.data;
          })
          .catch(e => {
            console.log(e.response);
          })
      },
      eliminarDemografia(id){
        this.$confirm('¿Deseas eliminar la demografía?', { title: 'Alerta' })
          .then(res => {
            if (res == true) {
              this.axios.delete(`/demografia/${id}`)
              .then(res => {
                const index = this.demografias.findIndex(item => item._id === res.data._id);
                this.demografias.splice(index,1);
                this.snackbar = true
                this.mensaje.texto = '¡Demografía eliminada!';
                this.listarDemografias();
              })
              .catch(e=> {
                this.snackbar = true
                this.mensaje.texto = '¡Esta demografía está siendo usado en una encuesta!';
                console.log(e.response);
              })
            }
          })
      },
      editarDemografia(item){
        if(this.$refs.formEditD.validate()){
          this.axios.put(`/demografia/${item._id}`, item)
            .then(res => {
              const index = this.demografias.findIndex(n => n._id === res.data._id);
              this.demografias[index].nombre = res.data.nombre;
              this.demografias[index].descripcion = res.data.descripcion;
              //PARA EDITAR EN EL MODAL
              this.snackbar = true
              this.mensaje.texto = '¡Demografía modificada!';
              this.editar = false;
              this.listarDemografias();
              this.$refs.formEditD.reset()
            })
            .catch(e=> {
              this.editar = false;
              this.snackbar = true
              this.mensaje.texto = '¡Esta demografía está siendo usado en una encuesta!';
              console.log(e.response);
            })
        }
      },
      listarDemografias(){
        this.axios('/demografias').then(response =>{
          // this.demografias = response.data.demografiasDB;
          this.demografias = response.data.arrayDemografia;
          this.demografias.sort((a,b) => new Date(b.date) - new Date(a.date));
        });
      },
      resetDemografia(){
        this.$refs.formEditD.reset()
      },
      //DESDE AQUI COMIENZA LOS ATRIBUTOS
      modalDemografia(item){
        this.editado.id = item._id;
        this.editado.nombre = item.nombre;
        this.axios.get(`/demografiaatributos/${item._id}`, item)
          .then(res => {
            console.log(res.data.arrayDemografiaAtributo);
            this.atributosDemografia = res.data.arrayDemografiaAtributo
          })
          .catch(e=> {
            console.log(e.response);
          })
      },
      agregarAtributoDemografia(demografiaId){
        if(this.atributosDemografia.length >= 18) {
          this.$props.popup("No se puede agregar más de 18 atributos demográficos.", "error");
          return;
        }
        if(this.$refs.formAddAD.validate()){
          const cuerpo = this.demografiaatributo;
          cuerpo.demografiaId= demografiaId;
          this.axios.post('/nueva-demografiaatributo', cuerpo)
            .then(res => {
                this.atributosDemografia.push(res.data)
                this.demografiaatributo.nombre = ''
                this.snackbar = true
                this.mensaje.texto = '¡Atributo demográfico agregado!'
                this.$refs.formAddAD.reset()
            })
            .catch(e => {
                console.log(e.response);
                this.$refs.formAddAD.reset()
                this.snackbar = true
                this.mensaje.texto = '¡Esta demografía está siendo usado en una encuesta!';
            })
        }
      },
      activarEdicionAtributoId(id){
        this.editarAtributo = true;
        console.log(id);
        this.axios.get(`/demografiaatributo/${id}`)
            .then(res => {
              console.log(res.data);
                this.atributodemografiaEditar = res.data;
            })
            .catch(e => {
                console.log(e.response);
            })
      },
      editarAtributoDemografia(item){
        if(this.$refs.formEditAD.validate()){
          this.axios.put(`/demografiaatributo/${item._id}`, item)
            .then(res => {
                const index = this.atributosDemografia.findIndex(n => n._id === res.data._id);
                this.atributosDemografia[index].nombre = res.data.nombre;
                this.editarAtributo = false;
                this.snackbar = true
                this.mensaje.texto = '¡Atributo demográfico modificado!';
                this.$refs.formEditAD.reset()
            })
            .catch(e=> {
                this.editarAtributo = false;
                this.snackbar = true
                this.mensaje.texto = '¡Este atributo demográfico está siendo usado en una encuesta!';
                console.log(e.response);
            })
        }
      },
      eliminarAtributoDemografia(id){
        this.$confirm('¿Deseas eliminar el atributo demográfico?', { title: 'Alerta' })
          .then(res => {
            if (res == true) {
              this.axios.delete(`/demografiaatributo/${id}`)
                .then(res => {
                    const index = this.atributosDemografia.findIndex(item => item._id === res.data._id);
                    this.atributosDemografia.splice(index,1);
                    this.snackbar = true
                    this.mensaje.texto = '¡Atributo demográfico eliminado!';
                })
                .catch(e=> {
                    this.snackbar = true
                    this.mensaje.texto = '¡Este atributo demográfico está siendo usado en una encuesta!';
                    console.log(e.response);
                })
            }
          })
      },
      resetAtributoDemografia(){
        this.$refs.formEditAD.reset()
      },
      //VALIDATE STATUS
      validateStatusDemografia(id){
        this.axios.put(`/demografiaValidate/${id}`)
          .then(res => {
            const index = this.demografias.findIndex(n => n._id === res.data._id);
            this.demografias[index].estado = res.data.estado;
            this.listarDemografias();
          })
          .catch(e => {
            console.log(e.response);
          })
      },
      validateNombreDemografia() {
        const nombre = this.demografia.nombre.toLowerCase();
        const unrecommended = ["genero", "sexo", "género"];
        if (unrecommended.includes(nombre)) {
          this.$props.popup("No es necesario agregar la demografía genero, ya que esta demografía se agrega por defecto en el sistema.", "info");
        }
      }
    },
    props: {
      popup: Function
    },
  }
</script>
